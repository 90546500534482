<template>
    <div @click="onSelect" :class="{'active': isSelected}" class="chat-user chat-user-item-container animate fadeUp delay-1">
        <div class="user-section">
            <div class="row valign-wrapper">
                <div class="col s12">
                	<template v-if="thread.user">
	                    <p class="m-0 blue-grey-text text-darken-4 font-weight-700">{{thread.user.name}}</p>
	                </template>
	                <template v-else-if="thread.partecipants.length > 0">
	                	<p class="m-0 blue-grey-text text-darken-4 font-weight-700">{{partecipants}}</p>
	                </template>
	                <p class="m-0 info-text" v-if="thread.last_message" v-html="thread.last_message.body"></p>
                </div>
            </div>
        </div>
        <div class="info-section">
            <div class="star-timing">
                <div class="time">
                    <span>{{thread.time}}</span>
                </div>
            </div>
        </div>
    </div>	
</template>

<script>
	export default {
		props: {
			thread: Object,
			isSelected: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			partecipants() {
				if (this.thread) {
					return this.thread.partecipants.map(i => i.name).join(', ')
				}
				return null
			}
		},
		data() {
			return {

			}
		},
		methods: {
			onSelect() {
				this.$emit('selected', {thread: this.thread})
			}
		}
	}
</script>