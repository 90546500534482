<script>
	import InfiniteLoading from 'vue-infinite-loading';
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			successSendText: String
		},
		components: {InfiniteLoading},
		data() {
			return {
				invite: null,
				mails: [],
				users: [],
				recipient_lists: [],
				infiniteId: +new Date(),
				pagination: {},
				fetch: new Form({
					id: null,
					term: null,				
					page: 1	
				}),		
				form: new Form({
					id: null,
					invite_id: null,
					mail_id: null,
					recipients: [],
					send_all: false
				})						
			}
		},
		computed: {
			recipients() {
				return this.users.filter(i => this.form.recipients.includes(i.id))
			},
			disabledSend() {
				return !this.form.send_all ? this.form.recipients.length == 0 : !this.form.send_all
			}
		},		
		watch: {
			'fetch.term': {
				handler(val) {
					this.infiniteId++
					this.$set(this, 'users', [])
					this.$set(this, 'pagination', {})
					this.$set(this.fetch, 'page', 1)
				}
			},
			'form.send_all': {
				handler(val) {
					if (val) {
						this.$set(this.form, 'recipients', [])
					}
				}
			} 
		},	
		mounted() {
			this.initModal()
			this.$bus.$on('invite-send-modal.show', this.init)
		},
		updated() {
			$("select").formSelect();

			this.initCheckedUsers()			
		},
		methods: {
			initModal() {
				$(this.$refs.modal).modal({
					dismissible: false
				})
				$("select").formSelect();
			},			
			async fetchMails() {
				await this.fetch
					.get(route('api.mails.list'))
					.then(response => {
						this.$set(this, 'mails', response.mails)
					})
			},			
			async init(invite) {
				this.$set(this, 'invite', invite)
				this.$set(this.form, 'recipients', invite.users.map(i => i.id))

				await this.fetchMails()

				if (this.mails.length > 0) {
					this.$set(this.form, 'mail_id', _.head(this.mails).id)
				}

				$(this.$refs.modal).modal('open')

				this.$refs.infiniteLoading.attemptLoad()
			},
			toggleOption(user, ev) {
				if (ev.target.checked) {
					this.form.recipients.push(user.id)

					this.recipient_lists.push(user)					
				} else {
					let idx = _.findIndex(this.form.recipients, i => i == user.id)
					if (idx >= 0) {
						this.form.recipients.splice(idx, 1)
						this.recipient_lists.splice(idx, 1)	
					}
				}
			},			
			initCheckedUsers() {
				this.users
					.forEach(i => {
						$(this.$refs.collection)
							.find(`input#checkbox-user-${i.id}`).prop(
								'checked', 
								this.form.recipients.includes(i.id)
							)
					})								
			},
			async fetchUsers($state) {
				await this.fetch
					.get(route('api.users.list'))
					.then(response => {
						this.$set(this, 'pagination', response.meta)
 						this.$set(this, 'users', _.uniqBy(this.users.concat(response.data), 'id'))
						this.$set(this.fetch, 'page', this.pagination.current_page + 1)

                        if (response.data.length > 0) {
                            $state.loaded()
                        } else {
                            $state.complete()
                        }					
					})
					.catch(e => console.log(e))
			},	
            infiniteHandler($state) {
                if (!_.isEmpty(this.pagination)) {
                    if (this.pagination.current_page === this.pagination.last_page) {
                        $state.complete()
                    } else {
                        this.fetchUsers($state)
                    }
                } else {
                    this.fetchUsers($state)
                }
            },
			onClose() {
				$(this.$refs.modal).modal('close')
				
				this.form.reset()

				this.$set(this, 'users', [])
				this.$set(this.form, 'recipients', [])
				this.$set(this, 'recipient_lists', [])

				$(this.$refs.collection).find('input').prop('checked', false)
			},     
			removeRecipient(idx) {
				this.form.recipients.splice(idx, 1)
			},
			onSubmit() {
				this.form
					.submit('post', route('api.invites.send', this.invite.id))
					.then(response => {
						this.onClose()

						setTimeout(() => {
							this.$swal({
								icon: 'success',
								text: this.successSendText			
							})	
						}, 500)
					})

			}			       						
		}	
	}
</script>