<script>
	import pptxgen from "pptxgenjs";
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			question: Object
		},
		data() {
			return {
				mapping: [],
				graph: null,
				labels: [],
				colors: [],
				form: new Form({})
			}
		},
		mounted() {
			this.$bus.$on('updata-graph-data', this.init)
		},
		methods: {
			init(payload) {
				this.$set(this, 'graph', payload.graphData)
				this.$set(this, 'mapping', payload.mapping)

				let labels = this.question.options.map(i => i.label)
				let colors = this.question.options.map(i => i.color)

				colors.forEach((c, idx) => {
					if (!_.isString(c)) {
						colors[idx] = '000000'
					} else {
						colors[idx] = colors[idx].replace('#', '').toUpperCase()
					}
				})	

				this.$set(this, 'labels', labels)
				this.$set(this, 'colors', colors)						
			},
			slugify(text) {
			  	return text.toString().toLowerCase()
				    .replace(/\s+/g, '-')           // Replace spaces with -
				    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
				    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
				    .replace(/^-+/, '')             // Trim - from start of text
				    .replace(/-+$/, '');            // Trim - from end of text
			},			
			async exportAs(format) {
				if (format == 'ppt') {
					await this.exportPPT()
				} else if (format == 'xlsx') {
					location.href = route('export.question_graph', this.question.id)
				} else if (format == 'image') {
					this.$bus.$emit('export-chart-image')
				}
			},
			async exportPPT() {
				let pptx = new pptxgen();	
				pptx.layout = 'LAYOUT_WIDE';

				let tblSlide = pptx.addSlide();
				let rows = [['Opzione', 'Totale', '%', 'Media']];
				let items = []

				this.mapping.forEach(i => {
					rows.push([
						{text: i.label},
						{text: i.total_count},
						{text: i.percentage},
						{text: i.base_average}
					])
				})

				tblSlide.addTable(rows, { 
					x: 0.5, y: 1.0, w: 9.0, 
					color: "363636", 
					border: {
						type: 'solid',
						pt: 1,
						color: 'DDDDDD'
					} 
				});	

				let slide = pptx.addSlide();
				let dataChart = [
				    {
				        name: this.question.title,
				        labels: this.labels,
				        values: this.mapping.map(i => i.total_count)
				    }
				];

				if (this.question.graph_type == 'bar') {
					slide.addChart(
						pptx.ChartType.bar, 
						dataChart, 
						{ 
							x: 1, y: 1, w: 8, h: 4, 
							barDir: 'col',
							chartColors: this.colors,
							showTitle: true,
							title: this.question.title 
						}
					);	
				} else if (this.question.graph_type == 'horizontalBar') {
					slide.addChart(
						pptx.ChartType.bar, 
						dataChart, 
						{ 
							x: 1, y: 1, w: 8, h: 4, 
							barDir: 'bar',
							chartColors: this.colors,
							showTitle: true,
							title: this.question.title 
						}
					);						
				} else if (this.question.graph_type == 'pie') {
					slide.addChart(
						pptx.ChartType.pie, 
						dataChart, 
						{ 
							x:1.0, y:1.0,
							chartColors: this.colors,
							showTitle: true,
							title: this.question.title,
							legendPos: 'r',
							dataLabelPosition: 'r',
							dataLabelFontSize: 9,
							dataLabelColor: '000000',
							dataLabelFormatCode: '##.##%'
						}
					);										
				}		

				await pptx
					.writeFile(`${this.slugify(this.question.title)}.pptx`)
				    .then(fileName => {
				    	//
				    });						
			},
		}
	}
</script>