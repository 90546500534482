<script>
	import Reactable from '../Commons/Reactable'
	import ReactionList from '../Commons/ReactionList'	
	import VideoPlayer from '../Commons/VideoPlayer'
	import AudioPlayer from '../Commons/AudioPlayer'
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			comment: Object,
			showTranslationLabel: String,
			showCloseTranslationLabel: String			
		},
		components: {Reactable,ReactionList,AudioPlayer,VideoPlayer},
		data() {
			return {
				showReply: false,				
				item: this.comment,
				replies: [],
				pagination: {},
				links: {},
				form: new Form({}),
				reactForm: new Form({
					emoji: null,
					class_path: this.comment.class_path
				}),
				threadForm: new Form({
					id: null,
					room_id: null,
					comment: this.comment
				})			
			}
		},
		computed: {
			loadMore() {
				if (!_.isEmpty(this.pagination)) {
					return this.item.descendants > 0 && this.pagination.current_page < this.pagination.last_page
				}
				return this.item.descendants > 0
			},
			source() {
				if (!_.isEmpty(this.links)) {
					return this.links.next
				}
				return route('comment_replies.index', this.item.id)
			},
            hasTranslation() {
                let translations = this.comment.translations
                if (Array.isArray(translations.body)) {
                    return false
                }

                return translations.body.hasOwnProperty('it')
            }			
		},
		mounted() {
			$(`#comment-dropdown-trigger-${this.item.id}`).dropdown({
				alignment: 'right',
				coverTrigger: true,
				constrainWidth: false
			})

			this.$bus.$on(`${this.item.class_path}-${this.item.id}`, this.updateReaction)
		},			
		methods: {
            getNodeTranslations() {
                let translations = []
                Object
                    .keys(this.item.translations.body)
                    .forEach(code => {
                        if (code != this.item.parent_locale) {
                            translations.push({
                                code,
                                text: this.item.translations.body[code]
                            })
                        }
                    })
                return translations
            },
            toggleTranslation(translation) {
                this.$set(this.item, 'showTranslation', !this.item.showTranslation)

                if (this.item.showTranslation) {
                    this.$set(this.item, 'current_translation_code', translation.code)
                } else {
                    this.$set(this.item, 'current_translation_code', null)
                }
                this.$set(this.item, 'displayedTranslation', translation.text)
            },			
			onDirectMessage(item, ev) {
				this.$set(this.threadForm, 'room_id', item.room_id)
				this.threadForm
					.submit('post', route('threads.store'))
					.then(response => {
						this.$bus.$emit('refresh-threads')
						this.$bus.$emit('thread:init', {item, thread: response.data})
						this.toggleDetail(ev)
					})
			},
			toggleDetail(e) {
				let $trigger = $(e.target).closest('.sidenav-trigger');
		        if (e.target && $trigger.length) {
		          	let sidenavId = M.getIdFromTrigger($trigger[0]);

		          	let sidenavInstance = document.getElementById(sidenavId).M_Sidenav;
		          	if (sidenavInstance) {
		            	sidenavInstance.open($trigger);
		          	}
		          	e.preventDefault();
		        }
			},			
			updateReaction({comment}) {
				this.$set(this.item, 'emojis', comment.emojis)
			},
			onNotify() {
				this.$bus.$emit('comments:notification:show', this.item)
			},
			onReacted(payload) {
				this.$set(this.reactForm, 'emoji', payload)
				this.reactForm
					.submit('put', route('comments.reactable', this.item.id))
					.then(({comment}) => {
						this.$set(this.item, 'emojis', comment.emojis)
					})
			},			
			onLoadReplies() {
				this.form
					.get(this.source)
					.then(response => {
						this.$set(this, 'replies', this.replies.concat(response.data))
						this.$set(this, 'pagination', response.meta)
						this.$set(this, 'links', response.links)
					})
			},
			onReply(comment) {
				this.showReply = true
				this.$bus.$emit(`prefix-user_${comment.commentable_id}`, {
					user: comment.author.name,
					source: comment.source_reply,
					data: comment
				})

				$(`div#commetable_${comment.parent_id}`).show()
			},
			refreshReplies() {
				this.showReply = !this.showReply
				this.form
					.get(route('comment_replies.index', this.item.id))
					.then(response => {
						this.$set(this, 'replies', response.data)
						this.$set(this, 'pagination', response.pagination)
						this.$set(this, 'links', response.links)

						this.$set(this.item, 'descendants', this.pagination.total)
					})				
			}
		}
	}
</script>