<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		data() {
			return {
				editor: null,
				category: null,
				fetch: new Form({}),
				form: new Form({
					name: null,
					position: null,
					description: null
				})
			}
		},
		mounted() {
			this.initEditor()

			this.$bus.$on('rooms-category:clone:show', this.load)
		},
		methods: {
			initEditor() {
				if (!this.editor) {
					this.editor = new Quill(this.$refs.editor, {
						bounds: this.$refs.editor,
						modules: {
						  	'formula': true,
						  	'syntax': true,
						  	'toolbar': {
						  		container: [
								    [{'font': []}, {'size': []}],
							    	['bold', 'italic', 'underline', 'strike'],
							    	[{'color': []}, {'background': []}],
							    	[{'script': 'super'}, {'script': 'sub'
							    	}],
							    	[{'header': '1'}, {'header': '2'}, 'blockquote', 'code-block'],
							    	[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
							    	['direction', {'align': []}],
							    	['link', 'image', 'video', 'formula'],
							    	['clean'],
							    	['emoji']
						  		],		  		
						  	},
							'emoji-toolbar': true,
							'emoji-shortname': true,			  	
						},
						theme: 'snow'
					});
					// add browser default class to quill select 
					let quillSelect = $("select[class^='ql-'], input[data-link]");
					quillSelect.addClass("browser-default");

			        this.editor.on('text-change', (e) => {
				    	this.$set(this.form, 'description', this.editor.root.innerHTML);
				    });	
				}	

			    this.editor.root.innerHTML = this.form.description
			},			
			load(payload) {
				this.$set(this, 'category', payload)

				this.$set(this.form, 'position', payload.position)
				$(this.$refs.modal).modal('open')
			},
			onSubmit() {
				this.form
					.submit('post', route('api.categories.clone', this.category.id))
					.then(response => {
						this.$swal({
							icon: 'success',
							text: 'Categoria clonata correttamente'			
						}).then(result => {
							this.$bus.$emit('refresh-categories')

							this.editor.root.innerHTML = ''

							$(this.$refs.modal).modal('close')
						})							
					})
			}			
		}
	}
</script>