<script>
	import {Form} from '../../helpers/Form/Form'
	
	export default {
		data() {
			return {
				languages: [],
				editor: null,
				project: null,
				fetch: new Form({}),
				form: new Form({
					name: null,
					fqdn: null,
					description: null,
					start_date: null,
					end_date: null,
					show_closed_room: false,
					lang: 'it'
				})
			}
		},
		watch: {
			'form.name': {
				handler(val) {
					if (!_.isNull(val)) {
						this.$set(this.form, 'fqdn', this.makeFqdn({name: val}))
					}
				}
			}
		},
		mounted() {
			this.initEditor()
			this.initModal()

			this.$bus.$on('projects:clone:show', this.load)
		},
		updated() {
			this.initEditor()
			this.initPlugin()
		},		
		methods: {
			initModal() {
				$(this.$refs.modal).modal()
			},			
			async load(payload) {
				this.$set(this, 'project', payload)
				this.$set(this.form, 'start_date', moment(payload.dates.start).format('YYYY-MM-DD'))
				this.$set(this.form, 'end_date', moment(payload.dates.end).format('YYYY-MM-DD'))
				this.$set(this.form, 'lang', payload.lang)
				this.$set(this.form, 'show_closed_room', payload.show_closed_room)

				await this.getLanguages()

				$(this.$refs.modal).modal('open')
			},			
			async getLanguages() {
				let response = await this.fetch.get(route('api.languages.get'))
				this.$set(this, 'languages', response.languages)
			},			
			initPlugin() {
				let vm = this

				$("select").formSelect();
			},
			initEditor() {
				if ($(".ql-toolbar").length) {
					return
				}

				this.editor = new Quill(this.$refs.editor, {
					bounds: this.$refs.editor,
					modules: {
					  	'formula': true,
					  	'syntax': true,
					  	'toolbar': {
					  		container: [
							    [{'font': []}, {'size': []}],
						    	['bold', 'italic', 'underline', 'strike'],
						    	[{'color': []}, {'background': []}],
						    	[{'script': 'super'}, {'script': 'sub'
						    	}],
						    	[{'header': '1'}, {'header': '2'}, 'blockquote', 'code-block'],
						    	[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
						    	['direction', {'align': []}],
						    	['link', 'image', 'video', 'formula'],
						    	['clean'],
						    	['emoji']
					  		],		  		
					  	},
						'emoji-toolbar': true,
						'emoji-shortname': true,			  	
					},
					theme: 'snow'
				});
				// add browser default class to quill select 
				let quillSelect = $("select[class^='ql-'], input[data-link]");
				quillSelect.addClass("browser-default");

		        this.editor.on('text-change', (e) => {
			    	this.$set(this.form, 'description', this.editor.root.innerHTML);
			    });		

			    this.editor.root.innerHTML = this.form.description
			},	
			slugify(str) {
			    str = str.replace(/^\s+|\s+$/g, ''); // trim
			    str = str.toLowerCase();
			  
			    // remove accents, swap ñ for n, etc
			    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
			    var to   = "aaaaeeeeiiiioooouuuunc------";
			    for (var i=0, l=from.length ; i<l ; i++) {
			        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
			    }

			    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
			        .replace(/\s+/g, '-') // collapse whitespace and replace by -
			        .replace(/-+/g, '-'); // collapse dashes

			    return str;
			},
			makeFqdn(project) {
				let project_slug = this.slugify(project.name) 
			    let fqdn = `${project_slug}.${location.hostname.replace('www.', '')}`;
			    return fqdn; 
			},
			onSubmit() {
				this.form
					.submit('post', route('api.projects.clone', this.project.id))
					.then(response => {
						this.$swal({
							icon: 'success',
							text: 'Progetto clonato correttamente'			
						}).then(result => {
							this.$bus.$emit('refresh-projects')

							$(this.$refs.modal).modal('close')
						})							
					})
			}								
		}
	}
</script>