<script>
	import InfiniteLoading from 'vue-infinite-loading';
	import ProjectCloneContainer from './ProjectCloneContainer'
	import ProjectCardContainer from './ProjectCardContainer'
	import {Form} from '../../helpers/Form/Form'

	export default {
		components: {ProjectCardContainer,ProjectCloneContainer,InfiniteLoading},
		data() {
			return {
				infiniteId: +new Date(),
				pagination: {},
				projects: [],
				fetch: new Form({
					id: null,
					status: 'all',
					page: 1
				}),
			}
		},
		watch: {
			'fetch.status': {
				handler(val) {
					this.refreshProjects()
				}
			}
		},
		mounted() {
			this.$bus.$on('refresh-projects', this.refreshProjects)
		},
		methods: {
			refreshProjects() {
				this.$set(this.fetch, 'page', 1)
				this.$set(this, 'projects', [])

				this.infiniteId += 1
			},
			getProjects($state) {
				this.fetch
					.get(route('api.projects.list'))
					.then(({projects}) => {
                        this.$set(this.fetch, 'page', projects.pagination.current_page + 1)
                        this.$set(this, 'pagination', projects.pagination)
                        this.$set(this, 'projects', this.projects.concat(projects.data))		

                        if (projects.data.length > 0) {
                            $state.loaded()
                        } else {
                            $state.complete()
                        }                   				
					})
			},
            infiniteHandler($state) {
                if (!_.isEmpty(this.pagination)) {
                    if (this.pagination.current_page === this.pagination.last_page) {
                        $state.complete()
                    } else {
                        this.getProjects($state)
                    }
                } else {
                    this.getProjects($state)
                }
            },				
		}
	}
</script>