<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			awaitingText: String,
		},
		data() {
			return  {
				rooms: [],
				selectAll: false,
				fetch: new Form({
					except_doneif: 'readonly',
					except: null,
					without_pagination: true
				}),
				form: new Form({
					rooms: []
				})
			}
		},
		watch: {
			selectAll(val) {
				let children = _.flatMap(this.rooms, 'children')

				if (val) {
					this.$set(this.form, 'rooms', this.rooms.map(i => i.id))
					this.$set(this.form, 'rooms', this.form.rooms.concat(children.map(i => i.id)))
				} else {
					this.$set(this.form, 'rooms', [])
				}
			}
		},
		mounted() {
			this.getRooms()
		},
		methods: {
			getRooms() {
				this.fetch
					.get(route('api.rooms.list'))
					.then(response => {
						this.$set(this, 'rooms', response.data.filter(i => i.type != 'socialemulator'))
					})
			},
			onSubmit() {
				this.$swal({
					title: this.awaitingText,
					allowEscapeKey: false,
					allowOutsideClick: false,
					onOpen: () => {
						this.$swal.showLoading();
					}
				})

				this.form
					.submit('post', route('api.exports'), {
						responseType: 'blob'
					}) 
					.then(response => {
						this.$swal.close();

	                    const blob = this.createBlob(response)

	                    FileSaver.saveAs(blob, `stanze.xlsx`);    
					})		
					.catch(error => {
						this.$swal.close();
					})	
			},
			createBlob(response) {
				return new Blob([response], {
	                type: 'application/octet-stream',
	            });    
			},			
		}
	}
</script>