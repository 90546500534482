<template>
	<div v-if="item">
		<canvas ref="graph"></canvas>
	</div>
</template>

<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			question: Object
		},
		data() {
			return {
				show_graph: true,
				item: this.question,
				graph: null,
				graphData: [],
				fetch: new Form({})
			}
		},
		watch: {
			'question.id': {
				handler(val) {
					this.graph.destroy()

					this.$set(this, 'item', this.question)
					this.getData()
				}
			}
		},
		mounted() {
			this.getData()

			this.$bus.$on('export-chart-image', this.downloadImage)
		},
		methods: {
			downloadImage() {
				let a = document.createElement("a"); //Create <a>
				a.href = this.graph.toBase64Image(); //Image Base64 Goes here
				a.download = "Image.png"; //File name Here
				a.click(); //Downloaded file				
			},
			init(labels, colors) {
				if (_.isNull(this.item.graph_type)) {
					return 
				}

				if (this.item.graph_type == 'pie') {
					this.graph = new Chart(this.$refs.graph.getContext('2d'), {
						type: this.item.graph_type,
						data: {
							datasets: [{
								data: this.graphData.map(i => i.total_count),
								backgroundColor: colors,
								label: this.item.title
							}],
							labels
						},
						options: {
							responsive: true,
							legend: {
								position: 'right'
							}
						}
					})
				} else {
					this.graph = new Chart(this.$refs.graph.getContext('2d'), {
						type: this.item.graph_type,
						data: {
							"labels": labels,
							"datasets": [
								{
									"label": this.item.title,
									"data": this.graphData.map(i => i.total_count),
									"fill": false,
									"backgroundColor": colors,
									"borderColor": colors,
									"borderWidth": 1
								}
							]
						},
						options: {
							responsive: true,
							"scales": {
								"yAxes": [
									{"ticks": {"beginAtZero":true}}
								]
							}
						}
					});
				}
			},
			randomScalingFactor() {
				return Math.round(Math.random() * 100);
			},			
			async getData() {
				await this.fetch
					.get(route('questions.graph', this.item.id))
					.then(response => {
						this.updateGraphData(response)
					})
					.catch(error => console.log('error', error))
			},
			updateGraphData({data, total, mapping}) {
				this.$set(this, 'show_graph', !_.isEmpty(data))

				let labels = this.item.options.map(i => i.label)
				let colors = this.item.options.map(i => i.color)
				
				if (this.item.options.length > 0) {
					this.graphData = this.question.options.map(i => {
						return {
							label: i.label,
							value: i.value,
							total_count: 0
						}
					})
				} else if (this.item.type == 'number') {
					for (let i = 0; i < mapping.length; i++) {
						labels.push(mapping[i].value)
						colors.push('#000000')

						this.graphData.push({
							label: mapping[i].value,
							value: mapping[i].value,
							total_count: mapping[i].total_count
						})
					}
				}

				colors.forEach((c, idx) => {
					if (!_.isString(c)) {
						colors[idx] = '#000000'
					}
				})				

				if (this.item.type == 'radio') {
					this.graphData.forEach((d, idx) => {
						data.forEach(i => {
							if (i.value == d.value) {
								this.$set(this.graphData[idx], 'total_count', this.graphData[idx].total_count + 1)
							}
						})
					})
				} else if (this.item.type == 'checkbox') {
					this.graphData.forEach((d, idx) => {
						data.forEach(i => {
							if (i.value.includes(d.value)) {
								this.$set(this.graphData[idx], 'total_count', this.graphData[idx].total_count + 1)
							}
						})	
					})	
				} else if (this.item.type == 'slider') {
					for (let start = this.item.settings.min.value; start <= this.item.settings.max.value; start++) {
						labels.push(start)
						colors.push('#000000')

						this.graphData.push({
							label: start,
							total_count: 0
						})
					}

					this.graphData.forEach((d, idx) => {
						data.forEach(i => {
							if (i.value == d.value) {
								this.$set(this.graphData[idx], 'total_count', mapping[idx].total_count)
							}
						})			
					})		
				}

				if (this.item.graph_type == 'pie') {
					this.graphData.forEach(i => {
						let idx = _.findIndex(mapping, m => m.label == i.label)
						if (idx >= 0) {
							i.total_count = (mapping[idx].total_count / total)
						}
					})
				}

				this.init(labels, colors)

				this.$bus.$emit('updata-graph-data', {
					graphData: this.graphData,
					mapping: mapping
				})
			}
		}
	}
</script>