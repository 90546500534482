<script>
	import UserPrivateThreadMessageForm from './UserPrivateThreadMessageForm'
	import VideoPlayer from '../Commons/VideoPlayer'
	import AudioPlayer from '../Commons/AudioPlayer'	
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
            showTranslationLabel: String,
            showCloseTranslationLabel: String,
		},
		components: {UserPrivateThreadMessageForm,VideoPlayer,AudioPlayer},
		data() {
			return {
				showTranslation: false,
				data: null,
				messages: [],
				pagination: {},
				thread: null,
				fetch: new Form({
					id: null,
					moderator_id: null,
					comment_id: null,
					user_id: null,					
					page: 1,
				})					
			}
		},
		computed: {
			listPartecipants() {
				if (this.thread) {
					return this.thread.partecipants.map(i => i.name).join(', ')
				}
				return null
			}
		},
		mounted() {
			this.$bus.$on('thread:init', this.init)	
		},
		updated() {
			this.setScroll();
		},
		methods: {			
            hasTranslation(node) {
                let translations = node.translations
                if (Array.isArray(translations.body)) {
                    return false
                }

                return translations.body.hasOwnProperty('it')
            },			
            getNodeTranslations(node) {
                let translations = []
                Object
                    .keys(node.translations.body)
                    .forEach(code => {
                        if (code != node.parent_locale) {
                            translations.push({
                                code,
                                text: node.translations.body[code]
                            })
                        }
                    })
                return translations
            },			
            toggleTranslation(node, translation) {
                this.$set(node, 'showTranslation', !node.showTranslation)

                if (node.showTranslation) {
                    this.$set(node, 'current_translation_code', translation.code)
                } else {
                    this.$set(node, 'current_translation_code', null)
                }
                
                this.$set(node, 'displayedTranslation', translation.text)
            },			
			init({thread, item, with_admin}) {
				this.messages = []
				this.$set(this, 'data', item)
				this.$set(this, 'thread', thread)

				this.messages.push(item)
				if (thread.messages.length > 0) {
					this.$set(this, 'messages', this.messages.concat(thread.messages))
				}
				this.setScroll();			
			},
			updateMessages({comment}) {
				this.messages.push(comment)

				this.setScroll();			
			},
			setScroll() {
				var charScroll = this.$refs.thredCollection;
				charScroll.scrollTop = charScroll.scrollHeight;
			},
            fetchMessages() {
            	this.fetch
            		.get(route('threads.get'))
            		.then(({data}) => {
                        this.$set(this.fetch, 'moderator_id', this.thread.moderator.id)
                        this.$set(this.fetch, 'user_id', this.thread.user.id)
                        this.$set(this.fetch, 'comment_id', this.thread.comment.id)

						this.$set(this, 'messages', this.messages.concat(data.messages.data))              			
            		})
            }		
		}
	}
</script>