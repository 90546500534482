<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			awaitingText: String,
		},
		data() {	
			return {
				languages: [],
				totalPendings: null,
				fetch: new Form({}),
				form: new Form({
					id: null,
					current_locale: null,
					locale: null,
					entity: null
				})
			}
		},
		watch: {
			'form.locale': {
				handler(val) {
					if (!val) return

					this.getPendingsCountByLocale()
				}
			}
		},
		mounted() {
			this.initModal()

			this.$bus.$on('modal-translator-all.show', this.getData)
		},
		methods: {
			async getPendingsCountByLocale() {
				let response = await this.form.get(route(`${this.form.entity}s.translate_all_count`))
				this.$set(this, 'totalPendings', response.counter)
			},
			initModal() {
				let vm = this

				$(this.$refs.modal).modal({
					onCloseEnd: function() {
						vm.form.reset()		
						vm.$set(vm, 'totalPendings', null)		
					}
				})
			},			
			async getData({entity}) {
				this.$set(this.form, 'entity', entity)				
				await this.getLanguages()

				$(this.$refs.modal).modal('open')		
			},
			async getLanguages() {
				let {assigned,currentLocale,languages} = await this.fetch.get(route('api.deepl.languages'))
				let currentLanguages = languages.filter(i => i.language.toLowerCase() != currentLocale).map(i => {
					return {
						code: i.language.toLowerCase(),
						name: i.name
					}
				})

				this.$set(this.form, 'current_locale', currentLocale)
				if (assigned.length > 0) {
					this.$set(this, 'languages', currentLanguages.filter(i => assigned.includes(i.code)))				
				} else {
					this.$set(this, 'languages', currentLanguages)				
				}
			},
			onSubmit() {
				$(this.$refs.modal).modal('close')

				this.$swal({
					title: this.awaitingText,
					allowEscapeKey: false,
					allowOutsideClick: false,
					onOpen: () => {
						this.$swal.showLoading();
					}
				})

				this.translateAllPendings()
			},
			translateAllPendings() {
				this.form
					.submit('post', route(`${this.form.entity}s.translate_all`))
					.then(response => {
						this.$bus.$emit(`refresh-${this.form.entity}s`)

						this.$swal.close();
					})
					.catch(error => {
						this.$swal.close();
					})
			}
		}
	}
</script>