<template>
    <ul v-if="hasPages" class="pagination">
        <li class="disabled" aria-disabled="true">
            <a href="#!" @click="onFirstPage">&laquo;</a>
        </li>

        <template v-for="p in filterPages">
        	<li v-if="isSelected(p)" class="disabled" aria-disabled="true"><a href="#!">{{ p.content }}</a></li>
        	<li v-else class="waves-effect"><a class="page-link" @click="setPage(p)">{{ p.content }}</a></li>
        </template>

        <li v-if="hasMorePages" class="waves-effect">
            <a class="page-link" @click="nextPageUrl" rel="next">
                &raquo;
            </a>
        </li>
        <li class="disabled" v-else>
            <a href="#!">&raquo;</a>
        </li> 
    </ul>
</template>

<script>
	export default {
		props: ['forcePage'],
		data() {
			return {
				pages: [],
				pagination: {},
				range: 3,
				selected: 0,
				breakView: {
					content: '...',
					disabled: true
				},
				margin: 1
			}
		},
		computed: {
			filterPages() {
				return this.pages.filter( Object );
			},
			hasPages() {
				if (this.pagination) {
					return this.pagination.last_page > 1;
				}
				return false
			},			
		},
		created() {
			this.$bus.$on('update-pagination', this.updateData);
		},
		methods: {
			updateData(pagination) {
				this.pages = [];
				this.pagination = pagination;
				this.selected = this.pagination.current_page - 1;

				this.getPages();
			},
			getPages() {
				if (this.pagination.last_page <= this.range) {
					for (let index = 0; index < this.pagination.last_page; index++) {
						this.pages[index] = {
							index: index,
							content: index + 1,
							selected: index === this.selected
						};
					}
				} else {
					let leftPart = this.range / 2;
					let rightPart = this.range - leftPart;

					if (this.selected < leftPart) {
						leftPart = this.selected;
						rightPart = this.range - leftPart;
					} else if (this.selected > (this.pagination.last_page - this.range / 2)) {
						rightPart = this.pagination.last_page - this.selected;
						leftPart = this.range - rightPart;
					}

					for (let index = 0; index < this.pagination.last_page; index++) {
						let page = {
							index: index,
							content: index + 1,
							selected: index === this.selected
						};

						if (index <= this.margin - 1 || index >= this.pagination.last_page - this.margin) {
							this.pages[index] = page;
							continue;
						}

						if ((this.selected - leftPart) > this.margin && this.pages[this.margin] !== this.breakView) {
							this.pages[this.margin] = this.breakView;
						}

						if ((this.selected + rightPart) < (this.pagination.last_page - this.margin - 1) && this.pages[this.pagination.last_page - this.margin - 1] !== this.breakView) {
							this.pages[this.pagination.last_page - this.margin - 1] = this.breakView;
						}

						let overCount = this.selected + rightPart - this.pagination.last_page + 1;

						if (overCount > 0 && index === this.selected - leftPart - overCount) {
							this.pages[index] = page;
						}

						if ((index >= this.selected - leftPart) && (index <= this.selected + rightPart)) {
							this.pages[index] = page;
							continue;
						}
					}

					return this.pages.filter( Object );
				}
			},
			hasMorePages() {
				return this.pagination.current_page < this.pagination.last_page;
			},
			onFirstPage() {
				this.setPage(1);
			},
			previousPageUrl() {
				if (this.pagination.current_page > 1) {
					this.setPage(this.pagination.current_page - 1);
				}
			},
			nextPageUrl() {
				if (this.pagination.current_page < this.pagination.last_page) {
					this.setPage(this.pagination.current_page + 1);
				}
			},
			lastPageUrl() {
				this.setPage(this.pagination.last_page);
			},
			setPage(p) {
				if (_.isNumber(p)) {
					this.$emit('pagination-change-page', p);
				} else {
					this.$emit('pagination-change-page', p.content);
				}
			},
			activeClass(p) {
				return p.selected ? ['uk-active'] : [''];
			},
			isSelected(p) {
				return _.isNull(p) ? false : p.selected;
			}
		}
	}
</script>