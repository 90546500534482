<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			defaultRole: String,
			user: {
				type: Object,
				default() {
					return {}
				}
			}
		},
		data() {
			return {
				languages: [],
				fetch: new Form({}),
				form: new Form({
					id: null,
					name: null,
					email: null,
					role: this.defaultRole,
					password: null,
					password_confirmation: null,
					languages: []
				})
			}
		},
		computed: {
			source() {
				if (this.user.hasOwnProperty('id')) {
					return route('users.update', this.user.id)
				}

				return route('users.store')
			},
			method() {
				return this.user.hasOwnProperty('id') ? 'put' : 'post'
			}
		},
		watch: {
			'form.role': {
				handler(val) {
					this.getLanguages()
				}
			}
		},
		mounted() {
			this.initUserForm()
		},
		methods: {
			initUserForm() {
				if (this.user.hasOwnProperty('id')) {
					this.$set(this.form, 'name', this.user.name)
					this.$set(this.form, 'email', this.user.email)
					this.$set(this.form, 'role', this.user.current_role)
					this.$set(this.form, 'languages', this.user.languages ? this.user.languages : [])
				}
			},
			async getLanguages() {
				let response = await this.fetch.get(route('api.deepl.languages'))
				let languages = response.languages.filter(i => i.language.toLowerCase() != response.currentLocale).map(i => {
					return {
						code: i.language.toLowerCase(),
						name: i.name
					}
				})

				this.$set(this, 'languages', languages)
			},
			toggleLanguages() {
				if (this.form.languages.length > 0) {
					this.$set(this.form, 'languages', [])
				} else {
					this.$set(this.form, 'languages', this.languages.map(i => i.code))
				}
			},
			onSubmit() {
				this.form
					.submit(this.method, this.source)
					.then(response => {
						this.$swal({
							icon: 'success',
							text: response.message			
						}).then(result => {
							location.href = response.redirect
						})								
					})
			}
		}
	}
</script>