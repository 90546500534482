<template>
    <div ref="reactable" class="display-inline relative" :id="`dropdown_${id}`">
        <span @click="toggle" class="dropdown-trigger">
        	<slot name="liked" :data="{toggle}">
	        	<i v-if="!label" class="material-icons vertical-align-bottom mr-1">thumb_up</i>
	        	<span v-else v-text="label"></span>
	        </slot>
        </span>

        <picker 
        	v-click-outside="toggle"
        	:style="customStyle"
        	v-if="show && !hideForMobile" 
        	@select="addReaction" 
        	:show-search="false" 
        	:show-preview="false" 
        	:data="emojiData" 
        	set="facebook" 
        />        
    </div>	
</template>

<script>
	import vClickOutside from 'v-click-outside'
	import data from 'emoji-mart-vue-fast/data/facebook.json'
	import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'

	export default {
		props: {
			label: String,
			reactable: Object,
			positionRight: {
				type: Boolean,
				default: false
			}
		},
		directives: {
		  	clickOutside: vClickOutside.directive
		},		
		components: {Picker},
		data() {
			return {
				hideForMobile: false,
				show: false,
				id: this.reactable.id,	
				emojiData: new EmojiIndex(data),				
			}
		},
		computed: {
			customStyle() {
				if (this.positionRight) {
					return {
						position: 'absolute', 
						bottom: '20px', 
						right: '0px', 
						zIndex: '1050',
						maxHeight: '200px',
						maxWidth: '270px'
					}
				}

				return {
					position: 'absolute', 
					bottom: '20px', 
					left: '0px', 
					zIndex: '1050',
					maxHeight: '200px',
					maxWidth: '270px'					 
				}				
			}
		},
		mounted() {
			this.initPicker()

            window.addEventListener('resize', () => {
                this.initPicker()
            });
		},
		methods: {
			initPicker() {
				this.$set(this, 'hideForMobile', window.isMobile())
				this.show = false
				
				this.$bus.$emit('modal:emoji:close')
			},
			toggle() {
				if (!this.hideForMobile) {
					this.show = !this.show
				} else {
					this.$bus.$emit('modal:emoji:show', this.reactable)					
				}
			},
			addReaction(emoji) {
				this.$emit('reacted', emoji)
				this.toggle()
			},			
		}
	}
</script>