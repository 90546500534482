<template>
  <div ref="feed" class="feed">
    <a ref="likeBtn" class="like-btn">
      <slot name="button"></slot>
      
      <div class="reaction-box">
        <template v-for="(reaction, idx) in reactionsList">
          <div :key="idx" @click="reactWith(reaction)" :class="[reaction.value]" class="reaction-icon">
            <label class="text-capitalize" v-text="reaction.value"></label>
          </div>
        </template>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    reactionsList: {
      type: Array,
      default() {
        return [
          {value: 'like'},
          {value: 'love'},
          {value: 'haha'},
          {value: 'wow'},
          {value: 'sad'},
          {value: 'angry'}
        ]
      }
    },
    reactable: Object,
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      this.$refs.likeBtn.addEventListener('mouseover', (ev) => {
        setTimeout(() => {
          const elements = document.getElementsByClassName('like-btn')
          for (const el of elements) {
            if (el.classList.contains('show') && el != this.$refs.likeBtn) {
              el.classList.remove('show')
              $(".reaction-icon", el).removeClass('show')
            }
          }

          this.$refs.likeBtn.classList.add('show')

          $(".reaction-icon", this.$refs.likeBtn).each(function(i, e) {
            setTimeout(() => {
              $(e).addClass("show");
            }, i * 100);
          });  
        }, 500)      
      })   

      this.$refs.likeBtn.addEventListener('click', (ev) => {
        const reactionIdx = this.reactable.emojis.findIndex(i => i.users.includes(window.user.id))
        const reaction = this.reactable.emojis[reactionIdx]

        if (reaction) {
          if (this.reactable.is_reactable) {
            this.$emit('unreactable', { id: reaction.id })
          }
        } else {
          this.$emit('reactable', { id: this.reactionsList[0] })
        }

        this.hideReactions()      

        ev.stopPropagation()
      })

      document.addEventListener("click", (evt) => {
        const flyoutEl = document.getElementById(`post-actions-container-${this.reactable.id}`);
        let targetEl = evt.target; // clicked element      
        do {
          if(targetEl == flyoutEl) {
            // This is a click inside, does nothing, just return.
            return;
          }
          // Go up the DOM
          targetEl = targetEl.parentNode;
        } while (targetEl);
        // This is a click outside.      
        this.hideReactions()
      });      
    },

    reactWith(reaction) {
      this.$refs.likeBtn.classList.remove('show')
      
      this.$emit('reactable', {
        id: reaction
      }) 
    },

    hideReactions() {
      if (this.$refs.likeBtn) {
        this.$refs.likeBtn.classList.remove('show')
        $(".reaction-icon", this.$refs.likeBtn).removeClass('show')
      }
    }
  }
}
</script>