<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		data() {
			return {
				languages: [],
				strings: [],
				locale: null,
				importForm: new Form({
					json: null,
					locale: null,
				}),
				fetch: new Form({
					id: null,
					locale: null,
					state: 'enabled'
				}),
				form: new Form({
					id: null,
					locale: null,
					strings: [],
					deleted: [],
					localeStrings: []
				})
			}
		},
		computed: {
			getLocaleClass() {
				return [`flag-icon-${this.locale}`]
			},	
		},
		watch: {
			'fetch.locale': {
				handler(val) {
					if (this.strings.hasOwnProperty(this.fetch.locale)) {
						this.$set(this.form, 'localeStrings', this.strings[this.fetch.locale])
					}
				}
			},
			'form.localeStrings': {
				handler(val) {
					this.$set(this.form.strings, this.fetch.locale, _.cloneDeep(val))
					this.$set(this.strings, this.fetch.locale, _.cloneDeep(val))
				},
				deep: true
			}
		},
		mounted() {
			this.initModal()

			this.init()
		},
		updated() {
			this.initPlugin()
		},		
		methods: {
			async init() {
				await this.getLanguages()
				await this.getStrings()
			},
			onClose() {
				$(this.$refs.modal).modal('close')
				this.form.reset()
			},			
			onChange(e) {
				let files = e.target.files || e.dataTransfer.files;
				if (!files.length) {	
					return;
				}
				this.$set(this.importForm, 'json', files[0]);			
			},
			async onImportTranslation() {
				let formData = new FormData;
				formData.append('locale', this.importForm.locale)
				formData.append('json', this.importForm.json)

				let response = await this.importForm.submitWith('post', route('api.strings.import'), formData)

				this.importForm.reset()
				$(this.$refs.modal).modal('close')
				await this.getStrings()

				this.$swal({
					icon: 'success',
					text: response.message
				})				
			},
			initModal() {
				$(this.$refs.modal).modal()
			},
			initPlugin() {
				let vm = this

				$("select").formSelect();
			},			
			async getLanguages() {
				let response = await this.fetch.get(route('api.languages.get'))
				this.$set(this, 'languages', response.languages)

				this.$set(this, 'locale', response.currentLocale)
				this.$set(this.fetch, 'locale', response.currentLocale)			
			},
			async getStrings() {
				let response = await this.fetch.get(route('api.strings.get'))
	
				this.$set(this.form, 'deleted', response.deleted)
				this.$set(this.form, 'strings', this.getFilteredStrings(response))
				this.$set(this, 'strings', this.getFilteredStrings(response))

				if (this.strings.hasOwnProperty(this.fetch.locale)) {
					this.$set(this.form, 'localeStrings', this.strings[this.fetch.locale])
				}				
			},
			getFilteredStrings(response) {
				let filtered = _.cloneDeep(response.strings)
				if (this.form.deleted.length > 0) {
					Object.keys(filtered)
						.forEach(i => {
							let localeStrings = filtered[i]
							filtered[i] = localeStrings.filter(l => {
								let idx = _.findIndex(this.form.deleted, d => d.label == l.label)
								return idx < 0
							})
						})
				}

				return _.cloneDeep(filtered)
			},
			async refresh() {
				this.$set(this.fetch, 'locale', this.locale)
				this.$set(this.form, 'locale', this.locale)

				await this.fetch.get(route('api.strings.refresh'))
				await this.getStrings()

				this.$swal({
					icon: 'success',
					text: 'Stringhe aggiornate correttamente!'
				})				
			},
			async onDownloadTranslation() {
				this.fetch
					.submit('post', route('api.strings.download'), {
						responseType: 'blob'
					}) 
					.then(response => {
	                    const blob = this.createBlob(response)

	                    FileSaver.saveAs(blob, `${this.fetch.locale}.json`);    
					})
			},
			async onSubmit() {
				this.$set(this.form, 'locale', this.fetch.locale)
				if (this.form.strings.length == 0) {
					alert('testing in corso...')
				}
				let {locale} = await this.form.submit('put', route('api.strings.update'))
				await this.getStrings(locale)

				this.$swal({
					icon: 'success',
					text: 'Stringhe salvate correttamente!'
				})
			},
            onDelete(idx) {
            	let el = this.strings[this.fetch.locale][idx]

            	this.form.deleted.push(el);
                this.strings[this.fetch.locale].splice(idx, 1)
            },		
			createBlob(response) {
				return new Blob([response], {
	                type : 'application/json',
	            });    
			},	            		
		}
	}
</script>