<script>
	import Draggable from 'vuedraggable'
	import Pagination from '../Commons/Pagination'
	import PostCloneContainer from './PostCloneContainer'
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			room: Number,
			perPage: {
				type: Number,
				default: 10
			}			
		},
		components: {Pagination,PostCloneContainer,Draggable},
		data() {
			return {
				pagination: {},
				posts: [],
				fetch: new Form({
					page: 1,
					per_page: this.perPage,
					with_body: 'included'
				}),
				form: new Form({
					positions: []
				})
			}
		},
		mounted() {
			this.fetchPosts()

			this.$bus.$on('refresh-posts', this.refreshPosts)
		},
		updated() {
			$(`.dropdown-trigger`).dropdown({
				alignment: 'right',
				coverTrigger: true,
				constrainWidth: false
			})	
		},
		methods: {	
			onTranslateAll() {
				this.$bus.$emit('modal-translator-all.show',{entity: 'post'})
			},
			postTranslationLangs(post) {
				let translations = post.translations.body
				return Object.keys(translations)
			},			
			openTranslator(post, type) {
				let postClone = _.cloneDeep(post)
				this.$bus.$emit('modal-translator.show', {post: postClone, type})
			},
			async updatePosition(data = {}) {
				if (data.hasOwnProperty('moved')) {
					this.posts.forEach((i, k) => {
						this.$set(i, 'position', k)
					})

					this.form.positions = this.posts.map(p => {
						return {
							id: p.id,
							position: p.position
						}
					})

					await this.form.submit('post', route('api.posts.update_positions'))
					await this.refreshPosts()
				}
			},
			editUrl(post) {
				let source = route('rooms.posts.edit', {
					room: this.room,
					post: post.id
				})
				return source
			},
			commentListUrl(post) {
				let source = route('posts.comments.index', post.id)
				return source
			},
			onClone(post) {
				this.$bus.$emit('posts:clone:show', post)
			},
			async refreshPosts() {
				this.$set(this.fetch, 'page', 1)
				this.$set(this, 'posts', [])

				await this.fetchPosts()
			},			
			onDelete(post) {
				this.form
					.submit('delete', route('api.posts.destroy', post.id))
					.then(response => {
						this.refreshPosts()
					})
			},			
			async fetchPosts() {
				if (this.room) {
					await this.fetch
						.get(route('posts.index', this.room))
						.then(response => {
							this.$set(this, 'posts', response.data)
							this.$set(this, 'pagination', response.meta)

							this.updatePosition()
						})
				} else {
					await this.fetch
						.get(route('translator.posts.index'))
						.then(response => {
							this.$set(this, 'posts', response.data)
							this.$set(this, 'pagination', response.meta)
						})					
				}

				this.$bus.$emit('update-pagination', this.pagination);
			},
			async updatePage(page) {
				this.$set(this.fetch, 'page', page)

				await this.fetchPosts()
			}			
		}
	}
</script>