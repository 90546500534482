<script>
	import InfiniteLoading from 'vue-infinite-loading';
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			room: Number,
			withAdmin: {
				type: Boolean,
				default: false
			}
		},
		components: {InfiniteLoading},
		data() {
			return {
				target: 'messages',
				search: null,
				infiniteId: +new Date(),
				threads: [],
				pagination: {},
				form: new Form({
					id: null,
					s: null,
					room_id: this.room,
					page: 1,
				}),
				fetch: new Form({
					id: null,
					moderator_id: null,
					comment_id: null,
					user_id: null,
					with_admin: this.withAdmin,
					page: 1
				})				
			}
		},
		watch: {
 			search(val) {
 				this.searchThreads(val, this)
 			}
		},
		mounted() {
			this.init()
			this.fetchThread()

			this.$bus.$on('refresh-threads', this.refreshThreads)
			this.$bus.$on('thread:last-message:update', this.updateThreads)
		},
		methods: {
			updateThreads({comment}) {
				if (comment.thread) {
					let idx = _.findIndex(this.threads, i => i.id == comment.thread)
					if (idx >= 0) {
						this.$set(this.threads[idx], 'last_message', comment)
					}
				}
			},
			refreshThreads(payload) {
				this.$set(this, 'target', payload.id)

				let elem = document.getElementsByClassName('tabs')[0]
				let instance = M.Tabs.getInstance(elem);
				instance.select(this.target)

				this.$set(this.form, 'page', 1)
				this.$set(this, 'pagination', {})
				this.$set(this, 'threads', [])

				this.infiniteId += 1
			},
			searchThreads: _.debounce((val, vm) => {
				vm.$set(vm.form, 's', val)
				vm.$set(vm.form, 'page', 1)
				vm.$set(vm, 'pagination', {})
				vm.$set(vm, 'threads', [])

				vm.infiniteId += 1
			}, 500),
            infiniteHandler($state) {
                if (!_.isEmpty(this.pagination)) {
                    if (this.pagination.current_page === this.pagination.last_page) {
                        $state.complete()
                    } else {
                        this.fetchThreads($state)
                    }
                } else {
                    this.fetchThreads($state)
                }
            },					
			async fetchThread() {
				this.$set(this, 'target', this.getParameterByName('t'))
				this.$set(this.fetch, 'moderator_id', this.getParameterByName('s'))
				this.$set(this.fetch, 'user_id', this.getParameterByName('u'))
				this.$set(this.fetch, 'comment_id', this.getParameterByName('pm'))

				if (!this.fetch.moderator_id) {
					return
				}

				try {
					let response = await this.fetch.get(route('threads.get'))

					if (response) {
						this.$bus.$emit('thread:init', {
							item: response.data.comment, 
							thread: response.data,
							with_admin: this.withAdmin
						})

			          	let sidenavInstance = document.getElementById('slide-out-chat').M_Sidenav;
			          	if (sidenavInstance) {
			            	sidenavInstance.open();
			          	}
					}
				} catch (e) {
					this.$swal({
						icon: 'error',
						text: e.message
					});							
				}
			},
			fetchThreads($state) {
				this.form
					.get(route('threads.index'))
					.then(response => {
						this.$set(this.form, 'page', response.meta.current_page + 1)
						this.$set(this, 'threads', this.threads.concat(response.data).filter(i => i.only_admin == this.withAdmin))
						this.$set(this, 'pagination', response.meta)

						if ($state) {
			                if (response.data.length > 0) {
			                    $state.loaded()
			                } else {
			                    $state.complete()
			                }   
			            }
					})				
			},
			init() {
	         	new PerfectScrollbar(".slide-out-right-body #messages, .chat-body .collection", {
		            suppressScrollX: true,
		            wheelPropagation: false
		        });

	         	if ($('.chat-body .collection').length) {
		        	new PerfectScrollbar(".chat-body .collection", {
		            	suppressScrollX: true
		        	});				
		        }

				//Main Left Sidebar Menu // sidebar-collapse
				$(".sidenav").sidenav({
				  	edge: "right" // Choose the horizontal origin
				});

				//Main Right Sidebar
				$(this.$refs.slideOutRight).sidenav({
				  	edge: "right"
				});

			   	//Main Right Sidebar Chat
			   	$(this.$refs.slideOutRightChat).sidenav({
			      	edge: "right"
			   	});	        	
			},
			onOpenDetail(thread, e) {
				this.$bus.$emit('thread:init', {
					thread,
					item: thread.comment,
					with_admin: this.withAdmin
				})
				this.toggleDetail(e)
			},
			toggleDetail(e) {
				let $trigger = $(e.target).closest('.sidenav-trigger');
		        if (e.target && $trigger.length) {
		          	var sidenavId = M.getIdFromTrigger($trigger[0]);

		          	let sidenavInstance = document.getElementById(sidenavId).M_Sidenav;
		          	if (sidenavInstance) {
		            	sidenavInstance.open($trigger);
		          	}
		          	e.preventDefault();
		        }
			},
            getParameterByName(name, url = null) {
                if (!url) url = window.location.href;
                name = name.replace(/[\[\]]/g, "\\$&");

                var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);

                if (!results) return null;
                if (!results[2]) return "";

                return decodeURIComponent(results[2].replace(/\+/g, " "));
            },					
		}
	}
</script>