<template>
    <div 
    	v-if="show" class="dropdown-emoji animate fadeUp delay-1" 
    	:style="styleObject"
    >
        <picker @select="addReaction" :show-search="false" :show-preview="false" :data="emojiData" set="facebook" />
    </div>	
</template>

<script>
	import data from 'emoji-mart-vue-fast/data/facebook.json'
	import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'

	export default {
		components: {Picker},
		data() {
			return {
				offset: null,
				show: false,
				emojiData: new EmojiIndex(data),
			}
		},
		computed: {
			styleObject() {
				return {
					top: `${this.offset.top}px`,
					left: `${this.offset.left}px`,
				}
			}
		},
		mounted() {
			this.$bus.$on('toggle-reaction', this.toggle)
		},
		methods: {
			toggle(payload) {
				this.show = payload.show
				this.offset = payload.element.getBoundingClientRect()
			},
			addReaction(emoji) {
				this.$emit('reacted', emoji)
				this.toggle()
			},
		}
	}
</script>