<template>
	<!-- Content Area -->
	<div class="chat-content-area animate fadeUp">
	    <!-- Chat header -->
	    <div v-show="thread.id" class="chat-header">
	        <div class="row valign-wrapper">
	            <div class="col media-image pr-0">
					<div class="circle z-depth-2 responsive-img">
	                	<template v-if="thread.user">
	                		<i class="material-icons">account_circle</i>
	                	</template>
	                	<template v-else-if="thread.partecipants.length > 0">
		                	<i class="material-icons">supervisor_account</i>
		                </template>
					</div>
	            </div>
	            <div class="col">
	            	<template v-if="thread.user">
		                <p class="m-0 blue-grey-text text-darken-4 font-weight-700">{{thread.user.name}}</p>
		            </template>
		            <template v-else-if="thread.partecipants.length > 0">
		            	<p class="m-0 blue-grey-text text-darken-4 font-weight-700">{{partecipants}}</p>
		            </template>
	            </div>
	        </div>
	        <span class="option-icon">
	        	<template v-if="thread.permissions.can_delete">
		        	<i @click="onDelete" class="material-icons">delete</i>
		        </template>
	        </span>
	    </div>
	    <!--/ Chat header -->

	    <!-- Chat content area -->
	    <div class="chat-area">
	        <div class="chats">
	            <div class="chats">
					<div class="chat mb-2" v-if="thread && thread.partecipants.length > 0">
						<p class="m-0 info-text center-align"><small>Hai aggiungo {{partecipants}} alla conversazione</small></p>
					</div>	            	
	                <template v-for="message in messages">
	                    <div class="chat" :class="{'chat-right': message.its_me}">
	                        <div class="chat-avatar">
	                            <div class="avatar circle">
	                                <i class="material-icons">account_circle</i>
	                            </div>
	                        </div>
	                        <div class="chat-body">
	                            <div class="chat-text">                      	
	                                <div class="chat-inner-text">
										<span>
											<span v-if="!message.its_me">{{message.author.name}}:</span>
											<span v-else>Tu:</span>
										</span>	      
                						<div v-if="message.images" class="display-flex flex-column justify-content-center">
                    						<div 
                    							class="card-attachment-image" 
                    							:style="{ backgroundImage: `url('${image.url}')` }" 
                    							v-for="image in message.images" 
                    							:key="image.id"
                    						></div>
                						</div>
						                <div v-if="message.video" class="display-flex flex-column justify-content-center">
						                    <video-player
						                        ref="video"
						                        :src="message.video.url"
						                        :options="{
						                            controls: true,
						                            fluid: true,
						                            fill: true,
						                            aspectRatio: '16:9',
						                            sources: [
						                                {src:message.video.url, type: message.video.type}
						                            ]
						                        }"
						                    ></video-player>
						                </div>
						                <div v-if="message.audio" class="display-flex flex-column justify-content-center">
						                    <audio-player :media="message.audio"></audio-player>
						                </div>   

	                                 	<div v-html="message.body"></div>
										<template v-if="message.showTranslation">
											<blockquote class="mt-2 mark animated-fadeIn" v-html="message.displayedTranslation"></blockquote>
										</template>
										<template v-if="message.permissions.see_translations">
											<div class="medium-small d-flex justify-content-between">
												<template v-for="translation in getNodeTranslations(message)">
													<a href="javascript:void(0);" :class="{'display-none': message.current_translation_code && translation.code != message.current_translation_code}" class="info-text mt-2 mr-2 display-flex align-items-center" v-if="hasTranslation(message)" @click="toggleTranslation(message, translation)">
														<span v-if="!message.showTranslation" v-text="showTranslationLabel"></span>
														<span v-else v-text="showCloseTranslationLabel"></span>

														<i class="ml-2 flag-icon" :class="[`flag-icon-${translation.code}`]"></i>
													</a>
												</template>
											</div>
										</template>	                                 	
	                                </div>
	                            </div>
	                        </div>
	                    </div>
	                </template>
	            </div>
	        </div>
	    </div>
	    <!--/ Chat content area -->

	    <!-- Chat footer <-->
	    <div v-show="thread.id" class="chat-footer">
          	<div v-if="showDropzone && !form.submitted" class="dropzone-dropdown-container mb-1">
                <dropzone 
                  	accept="image/png,image/jpeg,image/webp,video/mp4,video/x-m4v,video/*,audio/*"
                  	@size-exceeded="onExceededSize" 
                  	multiple 
                  	v-model="form.files"></dropzone>
            </div>    	    	
	        <div class="chat-input">          
              	<div class="comment-form-container display-flex justify-content-between align-items-center">
	                <span @click="toggleDropdown" class="mr-1 display-flex align-items-center clickable">
	                  	<i class="material-icons">camera_alt</i>
	                </span>              
	                <div ref="editor" class="comment-form-control bubble-editor"></div>
	                <button type="button" @click="onSubmit" class="btn-flat" :class="{'disabled': form.submitted}" :disabled="form.submitted">
	                  	<i class="material-icons prefix">send</i>
	                </button>                       
              	</div>  
	        </div>
	    </div>
	    <!--/ Chat footer -->
	</div>
	<!--/ Content Area -->	
</template>

<script>
	import Quill from 'quill'
	import Dropzone from '../Commons/Dropzone'
	import VideoPlayer from '../Commons/VideoPlayer'
	import AudioPlayer from '../Commons/AudioPlayer'	
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			placeholder: String,
			deleteThreadTitleText: String,
			cancelButtonText: String,
			confirmButtonText: String,
			successDeleteThreadText: String,
            showTranslationLabel: String,
            showCloseTranslationLabel: String,	
		},
		components: {Dropzone,VideoPlayer,AudioPlayer},
		data() {
			return {
				messages: [],
				thread: {
					id: null,
					partecipants: [],
					user: null,
					moderator: null,
					permissions: {
						can_delete: false
					},
				},
				source: null,
				showDropzone: false,
				editor: null,			
				form: new Form({
					is_private: true,
					body: null,
					files: [],
					videos: []					
				})
			}
		},
		computed: {
			isQuillEmpty() {
				if (this.editor) {
					return this.editor.getContents().ops[0].insert == '\n' && this.editor.getLength() < 2;
				}
				true
			},
			partecipants() {
				if (this.thread) {
					return this.thread.partecipants.map(i => i.name).join(', ')
				}
				return null
			},
			fullPartecipants() {
				let partecipants = []

				if (this.thread) {
					partecipants = this.thread.partecipants.concat(this.thread.moderator)

					return partecipants.map(i => i.name).join(', ')
				}
				return null				
			}			
		},		
		mounted() {
			this.initEditor()

			this.$bus.$on('init:thread', this.init)
		},
		methods: {
            hasTranslation(node) {
                let translations = node.translations
                if (Array.isArray(translations.body)) {
                    return false
                }

                return translations.body.hasOwnProperty('it')
            },			
            getNodeTranslations(node) {
                let translations = []
                Object
                    .keys(node.translations.body)
                    .forEach(code => {
                        if (code != node.parent_locale) {
                            translations.push({
                                code,
                                text: node.translations.body[code]
                            })
                        }
                    })
                return translations
            },			
            toggleTranslation(node, translation) {
                this.$set(node, 'showTranslation', !node.showTranslation)

                if (node.showTranslation) {
                    this.$set(node, 'current_translation_code', translation.code)
                } else {
                    this.$set(node, 'current_translation_code', null)
                }
                
                this.$set(node, 'displayedTranslation', translation.text)
            },			
			resetThread() {
				this.$set(this, 'thread', {
					id: null,
					partecipants: [],
					user: null,
					moderator: null,
					permissions: {
						can_delete: false
					}
				})
			},
			onDelete() {
				this.$swal({
					icon: 'info',
					title: this.deleteThreadTitleText,
					showCancelButton: true,
					cancelButtonText: this.cancelButtonText,
					confirmButtonText: this.confirmButtonText,	
				}).then(result => {
					if (result.isConfirmed) {				
						this.form
							.submit('delete', route('threads.destroy', this.thread.id))
							.then(response => {
								this.$swal({
									icon: 'success',
									text: this.successDeleteThreadText		
								}).then(result => {
									this.resetThread()

									this.$bus.$emit('refresh-threads')
								})								
							})
					}
				})
			},
			init(thread) {
				let messages = []

				if (thread.comment) {
					messages.push(thread.comment)

					this.$set(this, 'source', thread.comment.source_reply)
				} else {
					this.$set(this, 'source', route('threads.comments.store', thread.id))
				}

				messages = messages.concat(thread.messages)

				this.$set(this, 'thread', thread)
				this.$set(this, 'messages', messages)
			},
			onExceededSize(payload) {
				this.$swal({
					icon: 'error',
					text: `Dimensione file non valida: ${payload.size.toFixed(2)}MB`
				});
			},
			toggleDropdown() {
				this.showDropzone = !this.showDropzone
				this.$set(this.form, 'files', [])
			},			
			initSource({item}) {
				this.$set(this, 'source', item.source_reply)
			},			
			initEditor() {
				this.editor = new Quill(this.$refs.editor, {
					bounds: '.bubble-editor',
					placeholder: this.placeholder,
					modules: {
						'emoji-textarea': true,
						'toolbar': false,
					},
					theme: 'bubble'
				});
			},			
			onSubmit() {
				this.form.submitted = true;

				if (this.isQuillEmpty && this.form.files.length == 0 && this.form.videos.length == 0) {
					this.form.submitted = false
					return
				}

				if (!this.isQuillEmpty) {
					this.$set(this.form, 'body', this.editor.root.innerHTML)	
				}			

				setTimeout(async () => {
					let videos = this.getVideos()

					for (let i in videos) {
						let formData = new FormData
						formData.append('file', videos[i].file)
						formData.append('site', process.env.MIX_APP_SITE_ID)

						let {media,url} = await this.upload
							.submitWith('post', process.env.MIX_APP_VIDEO_UPLOAD_URL, formData)

						this.form.videos.push({
							id: media.id,
							mime: media.mime_type,
							url
						})
					}

					await this.form
						.submit('post', this.source)
						.then(response => {
							this.thread.messages.push(response.comment)

							this.showDropzone = false
							this.$set(this.form, 'files', [])
							this.$set(this.form, 'videos', [])
							this.editor.setText('')

							if (response.hasOwnProperty('thread')) {
								this.$set(this, 'thread', response.thread)
								this.messages.push(response.comment)
								//this.$bus.$emit('thread-update', response.comment)
							}
						})
				}, 2000)
			},	
			getVideos() {
				let videos = []
				this.form.files.forEach((i, idx) => {
					if (_.startsWith(i.mime, 'video')) {
						videos.push(i)

						this.form.files.splice(idx, 1)
					}
				})			

				return videos	
			}					
		}
	}
</script>