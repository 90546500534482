<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		data() {
			return {
				editor: {},
				comment: null,
				mails: [],
				fetch: new Form({}),
				form: new Form({
					mail_id: null,
					subject: null,
					message: null,
					recipients: []
				})
			}
		},
		mounted() {
			this.initModal()
			this.initEditor()

			this.$bus.$on('comments:notification:show', this.load)
		},
		updated() {
			$("select").formSelect();
		},
		methods: {
			onClose() {
				$(this.$refs.modal).modal('close')
				this.form.reset()
				this.$set(this.form, 'recipients', [])

				this.editor.root.innerHTML = null
			},
			async load(payload) {
				this.$set(this, 'comment', payload)

				this.form.recipients.push(payload.author.email)

				await this.fetchMails()

				$(this.$refs.modal).modal('open')
			},
			async fetchMails() {
				await this.fetch
					.get(route('api.mails.list'))
					.then(response => {
						this.$set(this, 'mails', response.mails)
					})
			},
			initModal() {
				$(this.$refs.modal).modal()
			},
			initEditor() {
				if ($(".ql-toolbar").length) {
					return
				}

				this.editor = new Quill(this.$refs.editor, {
					bounds: this.$refs.editor,
					modules: {
					  	'formula': true,
					  	'syntax': true,
					  	'toolbar': {
					  		container: [
							    [{'font': []}, {'size': []}],
						    	['bold', 'italic', 'underline', 'strike'],
						    	[{'color': []}, {'background': []}],
						    	[{'script': 'super'}, {'script': 'sub'
						    	}],
						    	[{'header': '1'}, {'header': '2'}, 'blockquote', 'code-block'],
						    	[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
						    	['direction', {'align': []}],
						    	['link', 'image', 'video', 'formula'],
						    	['clean'],
						    	['emoji']
					  		],		  		
					  	},
						'emoji-toolbar': true,
						'emoji-shortname': true,			  	
					},
					theme: 'snow'
				});
				// add browser default class to quill select 
				let quillSelect = $("select[class^='ql-'], input[data-link]");
				quillSelect.addClass("browser-default");

		        this.editor.on('text-change', (e) => {
			    	this.$set(this.form, 'message', this.editor.root.innerHTML);
			    });		

			    this.editor.root.innerHTML = this.form.message
			},			
			onSubmit() {
				this.form
					.submit('post', route('api.comments.notifications', this.comment.id))
					.then(response => {
						$(this.$refs.modal).modal('close')

						this.$swal({
							icon: 'success',
							text: 'Notifica inviata correttamente'			
						})			

						this.editor.root.innerHTML = null						
					})
			}
		}
	}
</script>