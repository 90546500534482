<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			data: Object
		},
		data() {
			return {
				fetch: new Form({}),
				form: new Form({
					id: null,
					title: null,
					password: null,
					host: null,
					port: null,
					encryption: '',
					username: null,
					from_email: null,
					from_name: null,
					status: false
				})
			}
		},
		computed: {
			source() {
				if (!_.isNumber(this.form.id)) {
					return route('api.mails.store')
				}

				return route('account-mails.update', this.form.id)
			},
			method() {
				return !_.isNumber(this.form.id) ? 'post' : 'put'
			}
		},
		mounted() {
			this.getAccount()
		},
		methods: {
			getAccount() {
				if (!_.isObject(this.data)) return

				for (let prop in this.data) {
					if (this.form.hasOwnProperty(prop)) {
						this.$set(this.form, prop, this.data[prop])
					}
				}
			},
			onSubmit() {
				this.form
					.submit(this.method, this.source)
					.then(response => {
						this.$swal({
							icon: 'success',
							text: 'Account salvato correttamente'			
						})		
					})
			},
			onCheckConnection() {
				this.form
					.submit('post', route('api.account-mails.check'))
					.then(response => {
						this.$swal({
							icon: 'success',
							text: 'Account salvato correttamente'			
						})	
					})
					.catch(error => {
						this.$swal({
							icon: 'error',
							text: 'Impossibile stabilire una connessione'
						});						
					})
			}
		}
	}
</script>
