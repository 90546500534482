<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			room: Number
		},
		data() {
			return {
				user: null,
				rooms: [],
				editor: {},
				fetch: new Form({}),
				form: new Form({
					room_id: this.room,
					user_status: null,
					subject: null,
					message: null,
					user_id: null,
					users: []
				})
			}
		},
		mounted() {
			this.initEditor()

			this.$bus.$on('user-reminder', this.setRecipient)
			this.$bus.$on('users-reminder', this.setRecipients)
		},	
		methods: {
			onSubmit() {
				this.form
					.submit('post', route('api.users.reminder'))
					.then(response => {
						$(this.$refs.modal).modal('close')
						this.onClose()

						this.$swal({
							icon: 'success',
							text: 'Sollecito inviato correttamente'			
						})
					})
			},
			onClose() {
				this.form.reset()
				this.$set(this, 'user', null)
				this.$set(this.form, 'users', [])

				$(this.$refs.modal).modal('close')

				$('input[name=select_all]').prop('checked', false)
				$('input[name=select_all]').trigger('change')
			},
			setRecipient(payload) {
				this.$set(this.form, 'user_id', payload)

				this.fetch
					.get(route('api.users.get', payload))
					.then(response => {
						this.$set(this, 'user', response.data)
					})
			},
			setRecipients() {
				this.form.reset()
				this.$set(this, 'user', null)
				this.$set(this.form, 'users', [])
 				if (this.room) {
 					this.$set(this.form, 'room_id', this.room)
 				}
 
				let vm = this
				$(".selection").each(function() {
					if (this.checked) {
						vm.form.users.push($(this).val())
					}
				})
			},
			initEditor() {
				if ($(".ql-toolbar").length) {
					return
				}

				this.editor = new Quill(this.$refs.editor, {
					bounds: this.$refs.editor,
					modules: {
					  	'formula': true,
					  	'syntax': true,
					  	'toolbar': {
					  		container: [
							    [{'font': []}, {'size': []}],
						    	['bold', 'italic', 'underline', 'strike'],
						    	[{'color': []}, {'background': []}],
						    	[{'script': 'super'}, {'script': 'sub'
						    	}],
						    	[{'header': '1'}, {'header': '2'}, 'blockquote', 'code-block'],
						    	[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
						    	['direction', {'align': []}],
						    	['link', 'image', 'video', 'formula'],
						    	['clean'],
						    	['emoji']
					  		],		  		
					  	},
						'emoji-toolbar': true,
						'emoji-shortname': true,			  	
					},
					theme: 'snow'
				});

				// add browser default class to quill select 
				let quillSelect = $("select[class^='ql-'], input[data-link]");
				quillSelect.addClass("browser-default");

		        this.editor.on('text-change', (e) => {
			    	this.$set(this.form, 'message', this.editor.root.innerHTML);
			    });		

			    this.editor.root.innerHTML = this.form.message
			},			
		}
	}
</script>