<template>
    <div class="reaction-container">
        <span 
        	v-for="emoji in filteredEmojis" 
        	:class="{'active': isYourReaction(emoji)}" 
        	class="tooltipped pointer animated fadeUp delay-1 z-depth-1 emoji-react-container"
        	@click="toggleReaction(emoji)"
            data-position="bottom" 
            :data-tooltip="getEmojiListContent(emoji)"
        >
            <emoji :data="index" set="facebook" :emoji="findEmoji(emoji.id)" :size="14" />
            <span class="emoji-badge" v-show="showCounter">{{emoji.count}}</span>
        </span>
        <span class="delay-1 z-depth-1 pointer emoji-react-container" v-if="emojis.length > 3" @click="onOpenDetail">
            <span>+{{remainingCount}}</span>
        </span>     
    </div>
</template>

<script>
    import { Emoji, EmojiIndex } from 'emoji-mart-vue-fast'
    import data from 'emoji-mart-vue-fast/data/facebook.json'

    export default {
        props: {
            emojis: Array,
            showCounter: {
                type: Boolean,
                default: true
            }
        },
        components: {Emoji},
        data() {
            return {
                index: new EmojiIndex(data),
                uuid: Math.random(),
            }
        },
        computed: {
            filteredEmojis() {
                return _.take(this.emojis, 3)
            },
            remainingCount() {
                return this.emojis.length - 3
            },
            emojiListContent() {
                let content = []

                this.emojis.forEach(i => {
                    let emoji = this.findEmoji(i.id)
                    content.push(`<div class="mb-2">${emoji.native} +${i.count}<br>${i.users_list.join(',')}</div>`)
                })

                return content.join('')
            }
        },
        mounted() {
            $('.tooltipped').tooltip({
                html: true
            });
        },
        methods: {
            getEmojiListContent(i) {
                let content = []
                
                let emoji = this.findEmoji(i.id)
                content.push(`<div class="mb-2">${emoji.native} +${i.count}<br>${i.users_list.join(',')}</div>`)

                return content.join('')
            },
            onOpenDetail() {
                $('#reaction-list-modal').modal('open');

                this.$bus.$emit('emojis-list', {
                    emojis: this.emojis
                })
            },
            findEmoji(emojiId) {
                return this.index.findEmoji(`:${emojiId}:`)
            },
            isYourReaction(emoji) {
                if (window.user.hasOwnProperty('id')) {
                    return emoji.users.includes(window.user.id)
                }
                return false
            },
            toggleReaction(emoji) {
                this.$emit('unreactable', emoji)
            },
            showReactions() {
                //
            }
        },
    }
</script>