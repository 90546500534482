<script>
	import Editor from '../Commons/Editor'
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			successMessage: String
		},
		components: {Editor},
		data() {
			return {
				type: 'manual',
				post: {
					title: null,
					body: null
				},
				currentLocale: 'it',
				assigned: [],
				languages: [],
				fetch: new Form({
					state: 'enabled'
				}),
				form: new Form({
					id: null,
					current_locale: null,
					locale: null,
					translations: {
						title: {},
						body: {}
					},
					translatable: {
						title: null,
						body: null
					},
					translated_by: {}
				})
			}
		},
		computed: {
			filterLanguages() {
				return this.languages.filter(i => this.form.locale != i.code)
			},
		},
		watch: {
			'form.locale': {
				handler(val) {
					let translatedBy = this.form.translated_by
					translatedBy[val] = this.type
					
					this.$set(this.form, 'translated_by', translatedBy)						
				}
			}
		},
		mounted() {
			this.initModal()

			this.$bus.$on('modal-translator.show', this.getData)
		},
		updated() {
			$(`.dropdown-trigger`).dropdown({
				alignment: 'right',
				coverTrigger: true,
				constrainWidth: false
			})
		},
		methods: {
			initModal() {
				let vm = this

				$(this.$refs.modal).modal({
					onCloseEnd: function() {
						vm.form.errors.clear()

						for (let prop in vm.form.translatable) {
							vm.$set(vm.form.translatable, prop, null)
						}						
					}
				})
			},
			switchLanguage(language = null) {
				if (language) {
					this.$set(this.form, 'locale', language.code)
				} else {
					language = {code: this.form.locale}
				}

				for (let prop in this.form.translatable) {
					this.$set(this.form.translatable, prop, this.form.translations[prop][language.code])
				}
			},		
			async getData({post,type}) {
				this.$set(this, 'post', post)
				this.$set(this, 'type', type)

				await this.getLanguages()
				this.initPost(post)
				this.switchLanguage()

				$(this.$refs.modal).modal('open')		
			},
			initPost(post) {
				let translations = post.translations
				this.$set(this.form.translations, 'title', Array.isArray(translations.title) ? {} : translations.title)
				this.$set(this.form.translations, 'body', translations.body)	

				if (Object.keys(comment.translated_by).length > 0) {
					this.$set(this.form, 'translated_by', comment.translated_by)	
				} else {
					let translatedBy = {}
					translatedBy[this.form.locale] = this.type
					
					this.$set(this.form, 'translated_by', translatedBy)	
				}				

				this.languages.forEach(i => {
					if (!this.form.translations.body.hasOwnProperty(i.code)) {
						this.form.translations.body[i.code] = null
					}		
					if (!this.form.translations.title.hasOwnProperty(i.code)) {
						this.form.translations.title[i.code] = null
					}										
				})			
			},
			async getLanguages() {
				let response = await this.fetch.get(route('api.deepl.languages'))
				let languages = response.languages.filter(i => i.language.toLowerCase() != response.currentLocale).map(i => {
					return {
						code: i.language.toLowerCase(),
						name: i.name
					}
				})

				this.$set(this, 'assigned', response.assigned)
				if (this.assigned.length > 0) {
					this.$set(this, 'languages', languages.filter(i => this.assigned.includes(i.code)))				
				}

				let firstLang = _.head(this.languages)

				this.$set(this, 'currentLocale', response.currentLocale)
				this.$set(this.form, 'locale', firstLang.code)
				this.$set(this.form, 'current_locale', response.currentLocale)			
			},
			onSubmit() {
				this.refreshTranslations()

				this.form
					.submit('put', route('api.posts.update', this.post.id))
					.then(response => {
						$(this.$refs.modal).modal('close')

						this.$swal({
							icon: 'success',
							text: this.successMessage
						})

						for (let prop in this.form.translatable) {
							this.$set(this.form.translatable, prop, null)
						}

						this.$bus.$emit('refresh-posts')
					})
			},
			onTranslate() {
				this.form
					.submit('post', route('deepl.translator'))
					.then(response => {
						let translations = response.translations
						for (let prop in translations) {
							this.$set(this.form.translatable, prop, translations[prop])
							this.$set(this.form.translations[prop], response.locale, translations[prop])
						}
					})
			},
			refreshTranslations() {
				for (let prop in this.form.translatable) {
					this.$set(this.form.translations[prop], this.form.locale, this.form.translatable[prop])
				}
			}
		}
	}
</script>