<script>
	import Dropzone from '../Commons/Dropzone'
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			invite: Number,
			successSavedText: String,
			successSendText: String
		},
		components: {Dropzone},
		data() {
			return {
				recipient_lists: [],
				infiniteId: +new Date(),
				pagination: {},
				data: {},
				users: [],
				mails: [],
				fetch: new Form({
					id: null,
					term: null,				
					page: 1	
				}),
				form: new Form({
					id: null,
					invite_id: null,
					mail_id: null,
					subject: null,
					recipients: [],
					message: null,
					send_all: false,
					attachments: []
				})
			}
		},
		computed: {
			recipients() {
				return this.users.filter(i => this.form.recipients.includes(i.id))
			},
			source() {
				if (_.isNumber(this.invite)) {
					return route('api.invites.update', this.invite)
				}

				return route('api.invites.store')
			},
			method() {
				if (_.isNumber(this.invite)) {
					return 'put'
				}

				return 'post'	
			}
		},
		mounted() {
			this.initEditor()
			this.fetchMails()

			this.getInvite()
		},
		updated() {
			this.initEditor()
		},
		methods: {
			isSelected(user) {
				return this.form.recipients.includes(user.id)
			},
			toggleOption(user, ev) {
				if (ev.target.checked) {
					this.form.recipients.push(user.id)

					this.recipient_lists.push(user)					
				} else {
					let idx = _.findIndex(this.form.recipients, i => i == user.id)
					if (idx >= 0) {
						this.form.recipients.splice(idx, 1)
						this.recipient_lists.splice(idx, 1)	
					}
				}
			},
			getInvite() {
				if (!_.isNumber(this.invite)) return

				this.fetch
					.get(route('api.invites.show', this.invite))
					.then(response => {
						this.$set(this, 'data', response.invite)

						this.$set(this.form, 'invite_id', response.invite.id)
						this.$set(this.form, 'recipients', response.invite.users.map(i => i.id))
						this.editor.root.innerHTML = this.data.message

						for (let prop in this.data) {
							if (this.form.hasOwnProperty(prop)) {
								this.$set(this.form, prop, this.data[prop])
							}
						}
					})
			},
			async fetchUsers($state, overwrite = true) {
				await this.fetch
					.get(route('api.users.list'))
					.then(response => {
						this.$set(this, 'pagination', response.meta)
 						this.$set(this, 'users', _.uniqBy(this.users.concat(response.data), 'id'))
						this.$set(this.fetch, 'page', this.pagination.current_page + 1)

                        $state.loaded()

                        if (response.data.length > 0) {
                            $state.loaded()
                        } else {
                            $state.complete()
                        }					
					})
					.catch(e => console.log(e))
			},	
			async fetchMails() {
				await this.fetch
					.get(route('api.mails.list'))
					.then(response => {
						this.$set(this, 'mails', response.mails)
					})
			},
			initEditor() {
				if ($(".ql-toolbar").length) {
					return
				}

				this.editor = new Quill(this.$refs.editor, {
					bounds: this.$refs.editor,
					modules: {
					  	'formula': true,
					  	'syntax': true,
					  	'toolbar': {
					  		container: [
							    [{'font': []}, {'size': []}],
						    	['bold', 'italic', 'underline', 'strike'],
						    	[{'color': []}, {'background': []}],
						    	[{'script': 'super'}, {'script': 'sub'
						    	}],
						    	[{'header': '1'}, {'header': '2'}, 'blockquote', 'code-block'],
						    	[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
						    	['direction', {'align': []}],
						    	['link', 'image', 'video', 'formula'],
						    	['clean'],
						    	['emoji']
					  		],		  		
					  	},
						'emoji-toolbar': true,
						'emoji-shortname': true,			  	
					},
					theme: 'snow'
				});
				// add browser default class to quill select 
				let quillSelect = $("select[class^='ql-'], input[data-link]");
				quillSelect.addClass("browser-default");

		        this.editor.on('text-change', (e) => {
			    	this.$set(this.form, 'message', this.editor.root.innerHTML);
			    });		

			    this.editor.root.innerHTML = this.form.message
			},	
			onSubmit() {
				let data = this.form.data()
				let formData = new FormData();

				for (let prop in data) {
					if (!['attachments', 'recipients'].includes(prop)) {
						formData.append(prop, data[prop] ? data[prop] : '')
					} else if (prop == 'attachments') {
						data[prop].forEach((i, idx) => {
							formData.append(`${prop}[${idx}]`, i.file)
						})
					} else if (prop == 'recipients') {
						data[prop].forEach((i, idx) => {
							formData.append(`${prop}[${idx}]`, i)
						})
					}

				}

				if (this.method == 'put') {
					formData.append('_method', this.method.toUpperCase())
				}

				this.form
					.submitWith('post', this.source, formData)
					.then(response => {
						this.$swal({
							icon: 'success',
							text: this.successSavedText		
						}).then(result => {
							this.editor.root.innerHTML = this.form.message		

							location.href = route('invites.index')		
						})										
					})
			},
			onSubmitAndSend() {
				this.form
					.submit('post', route('api.invites.send'))
					.then(response => {
						this.$swal({
							icon: 'success',
							text: this.successSendText
						}).then(result => {
							this.editor.root.innerHTML = this.form.message				
						})										
					})
			},
			removeRecipient(idx) {
				this.form.recipients.splice(idx, 1)
			},
            infiniteHandler($state) {
                if (!_.isEmpty(this.pagination)) {
                    if (this.pagination.current_page === this.pagination.last_page) {
                        $state.complete()
                    } else {
                        this.fetchUsers($state)
                    }
                } else {
                    this.fetchUsers($state)
                }
            },	
		}
	}
</script>