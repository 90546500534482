<template>
	<div>
		<input type="text" ref="datepicker" v-model="value" class="datepicker">
	</div>
</template>

<script>
	export default {
		model: {
			prop: 'date',
			event: 'update'
		},
		props: ['date', 'limitDate', 'startDate'],
		data() {
			return {
				value: null
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let vm = this
			
		        var instance = M.Datepicker.init(this.$refs.datepicker, {
					autoClose: true,
					format: 'dd/mm/yyyy',
					onSelect: function(date) {
						vm.$emit('update', moment(date).format('YYYY-MM-DD'))				
					},
					disableDayFn: function(date) {
						if (vm.limitDate) {
							let limit_at = moment(vm.limitDate)
							return moment(date).isAfter(limit_at)
						} else if (vm.startDate) {
							let start_at = moment(vm.startDate)
							return moment(date).isBefore(start_at) || moment(date).isSame(start_at)
						}						
					}				
				});				

				if (this.date) {
			        instance.setDate(new Date(this.date));
			        this.value = moment(this.date).format('DD/MM/YYYY')
			    }
			}
		}
	}
</script>