<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			page: Number
		},
		data() {
			return {
				locale: 'it',
				languages: [],
				editor: null,
				fetch: new Form({}),
				form: new Form({
					locale: 'it',
					id: null,
					title: null,
					body: null,
					status: true
				})
			}
		},
		mounted() {
			this.initEditor()
			this.fetchPage()
		},
		methods: {
			onChangeLocale(lang) {
				this.$set(this, 'locale', lang.code)
				this.$set(this.form, 'locale', lang.code)

				this.editor.trumbowyg('empty')
				this.editor.trumbowyg('html', this.form.body[lang.code]);
			},
			fetchPage() {
				this.fetch
					.get(route('api.pages.get', this.page))
					.then(response => {
						this.$set(this.form, 'locale', response.current_locale)
						this.$set(this, 'languages', response.languages)
						this.fillForm(response)
					})
			},
			initEditor() {
				$.trumbowyg.svgAbsoluteUsePath = true;
				$.trumbowyg.svgPath = '/images/trumbowyg-icons.svg';

				this.editor = $(this.$refs.editor).trumbowyg()
				this.editor.on('tbwchange', (e) => {
					this.$set(this.form.body, this.form.locale, this.editor.trumbowyg('html'))
				});
			},				
			onSubmit() {
				this.form
					.submit('put', route('api.pages.update', this.form.id))
					.then(response => {
						this.$swal({
							icon: 'success',
							text: 'Pagina salvata correttamente'			
						}).then(result => {
							this.fillForm(response)
						})	
					})
			},
			fillForm({page, translations}) {
				for (let prop in page) {
					if (translations.hasOwnProperty(prop)) {
						this.$set(this.form, prop, translations[prop])
					} else if (this.form.hasOwnProperty(prop)) {
						this.$set(this.form, prop, page[prop])
					}
				}

				this.editor.trumbowyg('empty')
				this.editor.trumbowyg('html', this.form.body[this.form.locale]);
			},		
			isSelectedLanguage(lang) {
				return lang.code == this.form.locale
			}
		}
	}
</script>