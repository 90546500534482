<script>
	import Draggable from 'vuedraggable'
	import {Form} from '../../helpers/Form/Form'

	export default {
		components: {Draggable},
		data() {
			return {
				rooms: [],
				fetch: new Form({
					without_pagination: true
				}),
				form: new Form({
					position: null,
					rooms: []
				})
			}
		},
		mounted() {
			this.getRooms()
		},		
		methods: {
			async updatePosition(data) {
				this.$set(this.form, 'position', data.moved.newIndex)
				this.rooms.forEach((i, k) => {
					this.$set(i, 'position', k)
				})				
				this.$set(this.form, 'rooms', this.rooms)	

				await this.form
					.submit('post', route('api.rooms.update_position', data.moved.element.id))	
					.then(response => {
						this.$swal({
							icon: 'success',
							text: response.message		
						})
					})
			},
			getRooms() {
				this.fetch
					.get(route('api.rooms.list'))
					.then(response => {
						response.data.forEach(i => {
							this.rooms.push(i)
							if (i.children.length > 0) {
								this.$set(this, 'rooms', this.rooms.concat(i.children))
							}
						})
					})
			},			
		}
	}
</script>