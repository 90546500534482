<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		data() {
			return  {	
				has_selection: false,
				comments: [],
				selectAll: false,
				fetch: new Form({
					id: null,
					room_id: '',
					author_id: '',
					page: 1
				}),
				form: new Form({
					medias: []
				})
			}
		},		
		watch: {
			selectAll(val) {
				if (val) {
					$(".media-table .media-checkbox").prop('checked', true)
				} else {
					$(".media-table .media-checkbox").prop('checked', false)
				}

				this.has_selection = $(".media-table .media-checkbox:checked").length
			}
		}
	}
</script>