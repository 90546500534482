<script>
	import Post from './Post'
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			room: Number,
			author: Number
		},
		components: {Post},
		data() {
			return {
				posts: [],
				pagination: {},
				links: {},
				form: new Form({
					author_id: this.author,
					page: 1,
					order_by: 'created_at'
				})
			}
		},
		computed: {
			hasMore() {
				if (!_.isEmpty(this.pagination)) {
					return this.pagination.current_page < this.pagination.total_pages
				}
				return this.posts.length > 0
			},			
			source() {
				if (!_.isEmpty(this.links)) {
					return this.links.next
				}
				return route('posts.index', this.room)
			},
			sourcePost() {
				return route('rooms.posts.store', this.room)
			}
		},
		mounted() {
			this.getPosts()
		},
		methods: {
			getPosts() {
				this.form
					.get(this.source)
					.then(response => {
						this.$set(this, 'pagination', response.pagination)
						this.$set(this, 'links', response.links)
						this.$set(this, 'posts', this.posts.concat(response.data))
					})
			},
			refreshPosts() {
				this.form
					.get(route('posts.index', this.room))
					.then(response => {
						this.$set(this, 'pagination', response.pagination)
						this.$set(this, 'links', response.links)
						this.$set(this, 'posts', response.data)
					})
			}
		}
	}
</script>