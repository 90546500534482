<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			room: Number
		},
		data() {
			return {
				table: null,
				fetch: new Form({
					id: null
				}),
				form: new Form({})
			}
		},
		computed: {
			columnWidth() {
				let width = 100
				if (this.table) {
					width = 100 / this.table.columns.length
				}
				return {width: `${width}%`}
			}
		},
		mounted() {
			this.getTable()
		},
		updated() {
			$('textarea').each(function() {
				M.textareaAutoResize($(this));
			})
		},
		methods: {
			getTable() {
				this.fetch
					.get(route('tables.get', this.room))
					.then(response => {
						this.$set(this, 'table', response.table)

						let answers = []
						for (let column in this.table.columns) {
							for (let i = 1; i <= this.table.rows; i++) {
								let key = this.getAnswerKey(this.table.columns[column], i)
								answers[key] = null
							}
						}

						Object.keys(this.table.entry).forEach(key => {
							if (answers.hasOwnProperty(key)) {
								answers[key] = this.table.entry[key]
							}
						})

						this.$set(this, 'form', new Form(Object.assign(answers, {
							required_rows: this.table.required_rows
						})))
					})
			},
			getAnswerKey(column, i) {
				return `answer_${column.id}_${i}`
			},
			onSubmit() {
				this.form
					.submit('post', route('tables.entry_store', this.table.id))
					.then(response => {
						this.$set(this, 'table', response.table)

						let answers = []
						for (let column in this.table.columns) {
							for (let i = 1; i <= this.table.rows; i++) {
								let key = this.getAnswerKey(this.table.columns[column], i)
								answers[key] = null
							}
						}

						Object.keys(this.table.entry).forEach(key => {
							if (answers.hasOwnProperty(key)) {
								answers[key] = this.table.entry[key]
							}
						})

						this.$set(this, 'form', new Form(Object.assign(answers, {
							required_rows: this.table.required_rows
						})))
					})
			}
		}
	}
</script>