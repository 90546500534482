<script>
	import InviteSendModal from './InviteSendModal'
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			deleteInviteText: String,
			cancelButtonText: String,
			confirmButtonText: String,
			successDeleteInviteText: String
		},
		components: {InviteSendModal},
		data() {
			return {
				form: new Form({}),
				invites: []
			}
		},
		mounted() {
			this.getInvites()
		},
		updated() {
			$(`.dropdown-trigger`).dropdown({
				alignment: 'right',
				coverTrigger: true,
				constrainWidth: false
			})	
		},		
		methods: {
			async getInvites() {
				let response = await this.form.get(route('api.invites.list'))
				this.$set(this, 'invites', response.invites)

				$(`.dropdown-trigger`).dropdown({
					alignment: 'right',
					coverTrigger: true,
					constrainWidth: false
				})					
			},
			editUrl(invite) {
				return route('invites.edit', invite.id)
			},
			onSend(invite) {
				this.$bus.$emit('invite-send-modal.show', invite)
			},
			onDelete(invite) {
				this.$swal({
					icon: 'info',
					title: this.deleteInviteText,
					showCancelButton: true,
					cancelButtonText: this.cancelButtonText,
					confirmButtonText: this.confirmButtonText,	
				}).then(result => {
					if (result.isConfirmed) {				
						this.form
							.submit('delete', route('invites.destroy', invite.id))
							.then(response => {
								this.$swal({
									icon: 'success',
									text: this.successDeleteInviteText	
								}).then(result => {
									this.getInvites()
								})								
							})
					}
				})
			}
		}
	}
</script>