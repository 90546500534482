<script>
	import Quill from 'quill'
	import Keyboard from 'quill/modules/keyboard'
	import Dropzone from '../Commons/Dropzone'
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			placeholder: String,
			customSource: String,
		},
		components: {Dropzone},
		data() {
			return {
				source: this.customSource,
				showDropzone: false,
				editor: null,
				form: new Form({
					target: 'messages',
					is_private: true,
					body: null,
					files: [],
					videos: []
				})
			}
		},
		computed: {
			isQuillEmpty() {
				if (this.editor) {
					return this.editor.getContents().ops[0].insert == '\n' && this.editor.getLength() < 2;
				}
				true
			}
		},
		mounted() {
			this.init()

			this.$bus.$on('thread:init', this.initSource)	
		},		
		methods: {
			onExceededSize(payload) {
				this.$swal({
					icon: 'error',
					text: `Dimensione file non valida: ${payload.size.toFixed(2)}MB`
				});
			},
			toggleDropdown() {
				this.showDropzone = !this.showDropzone
				this.$set(this.form, 'files', [])
			},			
			initSource({item, with_admin, thread}) {
				this.$set(this.form, 'target', thread.partecipants.length > 0 ? 'mods' : 'messages')
				this.$set(this, 'source', item.source_reply)
			},
			init() {
				this.editor = new Quill(this.$refs.editor, {
					bounds: '.bubble-editor',
					placeholder: this.placeholder,
					modules: {
						'emoji-textarea': true,
						'toolbar': false,
					},
					theme: 'bubble'
				});
			},			
			onSubmit() {
				this.form.submitted = true;

				if (this.isQuillEmpty && this.form.files.length == 0 && this.form.videos.length == 0) {
					this.form.submitted = false
					return
				}

				if (!this.isQuillEmpty) {
					this.$set(this.form, 'body', this.editor.root.innerHTML)	
				}			

				setTimeout(async () => {
					let videos = this.getVideos()

					for (let i in videos) {
						let formData = new FormData
						formData.append('file', videos[i].file)
						formData.append('site', process.env.MIX_APP_SITE_ID)

						let {media,url} = await this.upload
							.submitWith('post', process.env.MIX_APP_VIDEO_UPLOAD_URL, formData)

						this.form.videos.push({
							id: media.id,
							mime: media.mime_type,
							url
						})
					}

					await this.form
						.submit('post', this.source)
						.then(response => {
							this.$emit('thread-update', response)	
							this.$bus.$emit('thread:last-message:update', response)	

							this.showDropzone = false
							this.$set(this.form, 'files', [])
							this.$set(this.form, 'videos', [])
							this.editor.setText('')
						})
				}, 2000)
			},
			getVideos() {
				let videos = []
				this.form.files.forEach((i, idx) => {
					if (_.startsWith(i.mime, 'video')) {
						videos.push(i)

						this.form.files.splice(idx, 1)
					}
				})			

				return videos	
			}			
		}
	}
</script>