<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		data() {
			return  {
				has_selection: false,	
				selectAll: false,
				fetch: new Form({
					id: null,
					room_id: '',
					author_id: '',
					type: 'videos',
					page: 1
				}),
				form: new Form({
					medias: [],
					site: process.env.MIX_APP_SITE_ID
				})
			}
		},
		watch: {
			selectAll(val) {
				if (val) {
					$(".media-table .media-checkbox").prop('checked', true)
				} else {
					$(".media-table .media-checkbox").prop('checked', false)
				}

				this.has_selection = $(".media-table .media-checkbox:checked").length > 0
			}
		},
		mounted() {
			let vm = this
			$(".media-table .media-checkbox").on('change', function(e) {
				vm.has_selection = $(".media-table .media-checkbox:checked").length > 0
			})
		}
	}
</script>