<template>
    <div class="video-player-container">
        <video 
					ref="videoPlayer" 
					class="responsive-video video-js vjs-big-play-centered"
					data-setup='{}'
				></video>
    </div>
</template>

<script>
	import videojs from 'video.js'
	require('videojs-youtube')
	require('@devmobiliza/videojs-vimeo/dist/videojs-vimeo.cjs')

	export default {
	    props: {
	        options: {
	            type: Object,
	            default() {
	                return {};
	            }
	        },
	        src: String
	    },
	    data() {
	        return {
	            player: null
	        }
	    },
	    mounted() {
				try {
	        this.player = videojs(this.$refs.videoPlayer, this.options, this.onPlayerReady)

				} catch (e) {
					console.log(e)
				}
	    },
	    beforeDestroy() {
	        if (this.player) {
	            this.player.dispose()
	        }
	    },
	    methods:{
					onPlayerReady() {
						this.player.on('ended', (ev) => {
							this.$emit('ended', ev)
						})

						this.player.on('play', (e) => {
							const elements = document.getElementsByClassName('video-js')
							for (const el of elements) {
								if (el.player.id() != this.player.id()) {
									el.player.pause()
								}
							}

							this.$emit('play', e)
						})	
						
						this.player.on('pause', (e) => {
							this.$emit('pause', e)
						})

						this.player.on('seeking', (e) => {
							this.$emit('seeking', e)
						})	
						
						this.player.on('seeked', (e) => {
							this.$emit('seeked', e)
						})							
					},

	        play() {
						if (this.player) {
								this.player.play()
						}
	        },
	        pause() {
						if (this.player) {
								this.player.pause()
						}
	        }
	    }
	}
</script>