<script>
	import Pagination from '../Commons/Pagination'
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			awaitingText: String
		},
		components: {Pagination},
		data() {
			return {
				items: [],
				form: new Form({
					language_id: null
				}),
				fetch: new Form({
					id: null,
					page: 1,
					name: null,
					state: null,
				})
			}
		},
		mounted() {
			this.getLanguages()
		},
		methods: {
			async onReset() {
				this.$set(this, 'items', [])
				this.$set(this.fetch, 'page', 1)
				this.$set(this.fetch, 'name', null)
				this.$set(this.fetch, 'state', null)

				await this.getLanguages()
			},
			updatePage(page) {
				this.$set(this, 'items', [])
				this.$set(this.fetch, 'page', page)

				this.getLanguages();
			},			
			async getLanguages() {
				await this.fetch
					.get(route('api.languages.list'))
					.then(response => {
						this.$set(this, 'items', response.data)

						this.$bus.$emit('update-pagination', _.omit(response, 'data'));						
					})
			},
			async onUpdate(item) {
				this.$swal({
					title: this.awaitingText,
					allowEscapeKey: false,
					allowOutsideClick: false,
					onOpen: () => {
						this.$swal.showLoading();
					}
				})

				this.$set(this.form, 'state', item.state)

				await this.form.submit('put', route('api.languages.update', item.id))

				this.$swal.close();

				await this.onReset()
			},
			async onPublish(item) {
				this.$swal({
					title: this.awaitingText,
					allowEscapeKey: false,
					allowOutsideClick: false,
					onOpen: () => {
						this.$swal.showLoading();
					}
				})

				await this.form.submit('put', route('api.languages.publish', item.id))

				this.$swal.close();

				await this.onReset()

				this.$swal({
					icon: 'success',
					text: 'Lingua aggiornata correttamente!'
				})					
			},		
			onDelete({id}) {
				this.$swal({
					title: 'Sei sicuro di voler confermare la cancellazione?',
					text: "Non sarà possibile inveritre l'operazione!",
					type: 'warning',
					showCancelButton: true,
					cancelButtonText: 'Annulla',
					confirmButtonText: 'Sì, conferma!'
				}).then((result) => {
				  	if (result.value) {
						this.form
							.submit('delete', route('api.languages.destroy', id))
							.then(response => {
								this.onReset()
							})
				  	}
				})
			},				
		}
	}
</script>