<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			survey: Number
		},
		data() {
			return {
				answer: null,
				last_entry: null,
				pagination: {},
				questions: null,
				fetch: new Form({
					id: Math.random()
				}),
				form: null
			}
		},
		computed: {
			hasPages() {
				if (!_.isEmpty(this.pagination)) {
					return this.pagination.last_page > 1;
				}
				return false
			},
			hasMorePages() {
				return this.pagination.current_page < this.pagination.last_page;
			},	
			hasPrevPages() {
				return this.pagination.current_page > 1	
			},
			hasReachedEnd() {
				return this.pagination.current_page == this.pagination.last_page;
			}
		},
		mounted() {
			this.getSurvey()
		},
		methods: {
			async getSurvey() {
				let {questions, last_entry} = await this.fetch.get(route('api.surveys.show', this.survey))

				this.$set(this, 'questions', questions.data)
				this.$set(this, 'pagination', _.omit(questions, 'data'))
				this.$set(this, 'last_entry', last_entry)

				this.initFormData()

				await this.getAnswerFor(_.head(questions.data))
			},
			async getAnswerFor(question) {
				if (!_.isEmpty(question.child)) {
					await this.fetch
						.get(route('api.surveys.last_entry', question.child.id))
						.then(response => {
							if (!_.isEmpty(response.answer)) {				
								this.$set(this.form, question.child.key, response.answer)
							}
						})
				}

				await this.fetch
					.get(route('api.surveys.last_entry', question.id))
					.then(response => {
						this.$set(this, 'answer', response.answer)
						if (!_.isEmpty(response.answer)) {				
							this.$set(this.form, question.key, response.answer)
						}
					})
			},
			initFormData() {
				let keys = {id: Math.random()}

				if (_.isObject(this.form)) {
					keys = this.form.data()
				}

				this.questions.forEach(i => {
					if (!keys.hasOwnProperty(i.key)) {
						if (i.type == 'checkbox') {
							keys[i.key] = []
						} else if (i.type == 'slider') {
							keys[i.key] = 0
						} else {
							keys[i.key] = null
						}
					}
					if (!_.isNull(i.child)) {
						if (!keys.hasOwnProperty(i.child.key)) {
							keys[i.child.key] = null
						}
					}
				})

				this.$set(this, 'form', new Form(keys))
			},
			getLabel(question, option, idx) {
				let slug = this.slugify(option.value)
				return `${question.key}_${slug}-${idx}`
			},
			isOpenOption(question, option) {
				if (question.type == 'checkbox') {
					if (this.form[question.key].includes(option.value)) {
						return option.open
					}
				}

				return option.value == this.form[question.key] && option.open
			},
			async onSubmit() {
				await this.form
					.submit('post', route('surveys.store', this.survey))
					.then(response => {
						location.href = response.redirect
					})
			},
			async validate() {
				await this.form
					.submit('post', route('api.surveys.validation', this.survey))
			},
			async onNextQuestion() {
				try {
					if (!_.isObject(this.last_entry)) {
						await this.validate()
					}

					let {questions} = await this.fetch.get(this.pagination.next_page_url)

					this.$set(this, 'questions', questions.data)
					this.$set(this, 'pagination', _.omit(questions, 'data'))

					this.initFormData()

					await this.getAnswerFor(_.head(questions.data))	
				} catch(e) {
					//
				}			
			},
			async onPrevQuestion() {
				let {questions} = await this.fetch.get(this.pagination.prev_page_url)

				this.$set(this, 'questions', questions.data)
				this.$set(this, 'pagination', _.omit(questions, 'data'))

				this.initFormData()	

				await this.getAnswerFor(_.head(questions.data))	
			},
			slugify(str) {
			    str = str.replace(/^\s+|\s+$/g, ''); // trim
			    str = str.toLowerCase();
			  
			    // remove accents, swap ñ for n, etc
			    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
			    var to   = "aaaaeeeeiiiioooouuuunc------";
			    for (var i=0, l=from.length ; i<l ; i++) {
			        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
			    }

			    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
			        .replace(/\s+/g, '-') // collapse whitespace and replace by -
			        .replace(/-+/g, '-'); // collapse dashes

			    return str;
			},					
		}
	}
</script>