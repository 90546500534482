<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			room: Number
		},
		data() {
			return {
				fetch: new Form({}),
				pagination: {}
			}
		},
		mounted() {
			this.fetchStatus()

			this.$bus.$on('update-room-pagination', this.fetchStatus)
		},
		methods: {
			fetchStatus() {
				this.fetch
					.get(route('api.rooms.show', this.room))
					.then(response => {
						this.$set(this, 'pagination', response.pagination)
					})
					.catch(error => console.log('error', error))
			}
		}
	}
</script>