<script>
	import PostComment from './PostComment'

	export default {
		name: 'post-comment-list',
		props: {
			comments: Array,
		},
		components: {PostComment},
		data() {
			return {
				items: this.comments
			}
		}
	}
</script>