<script>
import {Form} from '../../helpers/Form/Form'

export default {
	props: {
		id: Number,
		btnConfirmLabel: String,
		btnCancelLabel: String,
		alertMessage: String,
		alertConfirmDeleteMessage: String
	},
	data() {
		return {
			form: new Form({})
		}
	},
	methods: {
		onDelete() {
			this.$swal({
				icon: 'info',
				title: this.alertMessage,
				showCancelButton: true,
				cancelButtonText: this.btnCancelLabel,
				confirmButtonText: this.btnConfirmLabel,	
			}).then(result => {
				if (result.isConfirmed) {				
					this.form
						.submit('delete', route('users.destroy', this.id))
						.then(response => {
							this.$swal({
								icon: 'success',
								text: this.alertConfirmDeleteMessage		
							}).then(result => {
								location.reload()
							})								
						})
				}
			})			
		}
	}
}
</script>