window._ = require('lodash')
window.videojs = require('video.js')
window.moment = require('moment')
window.katex = require('katex')
window.hljs = require('highlight.js')
window.Chart = require('chart.js')
window.FileSaver = require('file-saver')
window.PerfectScrollbar = require('../vendors/perfect-scrollbar/perfect-scrollbar.min')

window.isMobile = () => {
    if( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ){
        return true;
    }
    else {
        return false;
    }
}

try {
    window.$ = window.jQuery = require('jquery');

    require('./vendors.min')

	require('quill-emoji')
	require('../assets/js/ziggy')   
	require('../vendors/select2/select2.full.min')
	require('bootstrap-colorpicker')
    require('trumbowyg')

	window.noUiSlider = require('nouislider')
	window.wNumb = require('wnumb')

	window.Quill = require('quill')
} catch (e) {

}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}