<script>
	import InfiniteLoading from 'vue-infinite-loading';
	import RoomCardContainer from './RoomCardContainer'
	import RoomCloneContainer from './RoomCloneContainer'
	import {Form} from '../../helpers/Form/Form'

	export default {
		components: {RoomCardContainer,RoomCloneContainer,InfiniteLoading},
		data() {
			return {
				infiniteId: +new Date(),
				pagination: {},
				rooms: [],
				fetch: new Form({
					page: 1
				})
			}
		},
		mounted() {
			this.$bus.$on('refresh-rooms', this.refreshRooms)
		},
		methods: {
			refreshRooms() {
				this.$set(this, 'pagination', {})
				this.$set(this.fetch, 'page', 1)
				this.$set(this, 'rooms', [])

				this.infiniteId += 1;
			},
			getRooms($state) {
				this.fetch
					.get(route('api.rooms.list'))
					.then(response => {
                        this.$set(this.fetch, 'page', response.meta.current_page + 1)
                        this.$set(this, 'pagination', _.omit(response, ['data']))
                        this.$set(this, 'rooms', this.rooms.concat(response.data))

                        $state.loaded()

                        if (response.data.length > 0) {
                            $state.loaded()
                        } else {
                            $state.complete()
                        }						
					})
			},
            infiniteHandler($state) {
                if (!_.isEmpty(this.pagination)) {
                    if (this.pagination.meta.current_page === this.pagination.meta.last_page) {
                        $state.complete()
                    } else {
                        this.getRooms($state)
                    }
                } else {
                    this.getRooms($state)
                }
            },			
		}
	}
</script>