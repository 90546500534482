<template>
    <div class="row display-flex align-items-center">
    	<div class="col m8 s12">
	        <div class="input-field">
	            <select v-model="form.targets" class="validate" multiple>
	                <option v-for="target in targets" :value="target.id" v-text="target.name"></option>
	            </select>
	            <label>{{filterLabelText}}</label>
	        </div>
	    </div>
        <div class="col m4 s12">
        	<div class="display-flex justify-content-end align-items-center">
	            <button @click="onFilters" :disabled="form.targets.length == 0" class="btn-flat">
	                <i class="material-icons right">search</i>
	            </button>       
	            <button @click="onClearFilters" :disabled="form.targets.length == 0" class="red-text btn-flat">
	                <i class="material-icons right">clear</i>
	            </button>                       
	        </div>
        </div>
    </div>
</template>

<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			post: Object,
			filterLabelText: String
		},
		data() {
			return {
				targets: [],
				fetch: new Form({}),
				form: new Form({
					targets: []
				})
			}
		},
		beforeMount() {
			this.getTargets()
		},
		updated() {
			$("select").formSelect();
		},
		methods: {
			async getTargets() {
				let response = await this.fetch.get(route('api.targets.index'))
				this.$set(this, 'targets', response.targets)
			},
			onFilters() {
				this.$bus.$emit(`${this.post.id}.comments.filter`, this.form.targets)
			},
			onClearFilters() {
				this.$set(this.form, 'targets', [])
				this.$bus.$emit(`${this.post.id}.comments.filter`, [])
			}
		}
	}
</script>