<script>
	import Post from './Post'
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			room: Number,
			author: Number
		},
		components: {Post},
		data() {
			return {
				posts: [],
				form: new Form({}),
				pagination: {},
				links: {}
			}
		},
		computed: {
			hasMore() {
				if (!_.isEmpty(this.pagination)) {
					return this.pagination.current_page < this.pagination.last_page
				}
				return this.posts.length > 0
			},
			source() {
				if (!_.isEmpty(this.links)) {
					return this.links.next
				}
				return route('posts.index', this.room)
			}			
		},
		mounted() {
			this.getPosts()
		},
		methods: {
			getPosts() {
				this.form
					.get(this.source)
					.then(response => {
						this.$set(this, 'posts', this.posts.concat(response.data))
						this.$set(this, 'pagination', response.meta)
						this.$set(this, 'links', response.links)						
					})
			}
		}
	}
</script>