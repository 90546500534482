<template>
	<div id="reaction-list-modal" class="modal modal-emojis">
		<div class="modal-content">
		    <div class="row">
		      	<div class="col s12">
			        <ul class="emoji-tabs tabs-fixed-width">
			          	<li v-for="(emoji, idx) in emojis" class="tab col m3">
			          		<span class="tab-link clickable" @click="onSelect(emoji)" :class="{'active': isSelected(emoji)}">
			          			<emoji :data="index" set="facebook" :emoji="findEmoji(emoji.id)" :size="14" />
			          		</span>
			          	</li>
			        </ul>
		      	</div>
		      	<div v-for="(emoji, idx) in emojis" :id="`emoji${idx}`" v-show="isSelected(emoji)" class="col s12">
		      		<ul>
		      			<li v-for="user in emoji.users_list" v-text="user"></li>
		      		</ul>
		      	</div>
		    </div>		    
		</div>
	</div>	
</template>

<script>
    import { Emoji, EmojiIndex } from 'emoji-mart-vue-fast'
    import data from 'emoji-mart-vue-fast/data/facebook.json'

	export default {
		components: {Emoji},
		data() {
			return {
				selected: null,
				emojis: [],
				index: new EmojiIndex(data),
			}
		},
		mounted() {
			this.$bus.$on('emojis-list', this.initList)
		},
		methods: {
			initList(payload) {
				this.$set(this, 'emojis', payload.emojis)

				this.$set(this, 'selected', _.head(payload.emojis).id)
			},
            findEmoji(emojiId) {
                return this.index.findEmoji(`:${emojiId}:`)
            },			
            isSelected(emoji) {
            	if (this.selected) {
            		return emoji.id == this.selected
            	}
            	return false
            },
            onSelect(emoji) {
            	this.$set(this, 'selected', emoji.id)
            }
		}
	}
</script>