<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		data() {
			return {
				editor: null,
				post: null,
				room: null,
				form: new Form({
					title: null,
					body: null,
					room_id: null
				})
			}
		},
		mounted() {
			this.initEditor()
			this.initModal()

			this.$bus.$on('posts:clone:show', this.load)
		},
		methods: {
			initModal() {
				$(this.$refs.modal).modal()
			},				
			load(payload) {
				this.$set(this, 'post', payload)
				this.$set(this, 'room', payload.postable)
				this.$set(this.form, 'room_id', payload.postable.id)

				$(this.$refs.modal).modal('open')
			},
			initEditor() {
				this.editor = new Quill(this.$refs.editor, {
					bounds: this.$refs.editor,
					modules: {
					  	'formula': true,
					  	'syntax': true,
					  	'toolbar': {
					  		container: [
							    [{'font': []}, {'size': []}],
						    	['bold', 'italic', 'underline', 'strike'],
						    	[{'color': []}, {'background': []}],
						    	[{'script': 'super'}, {'script': 'sub'
						    	}],
						    	[{'header': '1'}, {'header': '2'}, 'blockquote', 'code-block'],
						    	[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
						    	['direction', {'align': []}],
						    	['link', 'image', 'video', 'formula'],
						    	['clean'],
						    	['emoji']
					  		],		  		
					  	},
						'emoji-toolbar': true,
						'emoji-shortname': true,			  	
					},
					theme: 'snow'
				});
				// add browser default class to quill select 
				let quillSelect = $("select[class^='ql-'], input[data-link]");
				quillSelect.addClass("browser-default");

		        this.editor.on('text-change', (e) => {
			    	this.$set(this.form, 'body', this.editor.root.innerHTML);
			    });		

			    this.editor.root.innerHTML = this.form.body
			},	
			async onSubmit() {
				await this.form
					.submit('post', route('api.posts.clone', this.post.id))
					.then(response => {
						this.$swal({
							icon: 'success',
							text: 'Post clonato correttamente'			
						}).then(result => {
							this.$bus.$emit('refresh-posts')

							this.editor.root.innerHTML = ''

							$(this.$refs.modal).modal('close')
						})	
					})
			}		
		}
	}
</script>