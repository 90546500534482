<script>
	import Pagination from '../Commons/Pagination'
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			post: Number,
			perPage: {
				type: Number,
				default: 10
			}
		},
		components: {Pagination},
		data() {
			return {
				pagination: {},
				comments: [],
				form: new Form({}),
				fetch: new Form({
					id: null,
					page: 1,
					perPage: this.perPage
				})
			}
		},
		mounted() {
			this.fetchComments()

			this.$bus.$on('refresh-comments', this.fetchComments)
		},
		updated() {
			$(`.dropdown-trigger`).dropdown({
				alignment: 'right',
				coverTrigger: true,
				constrainWidth: false
			})	
		},		
		methods: {
			onTranslateAll() {
				this.$bus.$emit('modal-translator-all.show',{entity: 'comment'})
			},			
			commentTranslationLangs(comment) {
				let translations = comment.translations.body
				return Object.keys(translations)
			},
			openTranslator(comment, type) {
				let commentClone = _.cloneDeep(comment)
				this.$bus.$emit('modal-translator.show', {comment: commentClone, type})
			},			
			onNotify(comment) {
				this.$bus.$emit('comments:notification:show', comment)
			},
			async refreshComments() {
				this.$set(this.fetch, 'page', 1)
				this.$set(this, 'comments', [])

				await this.fetchComments()
			},
			editUrl(comment) {
				let source = route('posts.comments.edit', {
					post: this.post, 
					comment: comment.id
				})

				return source
			},
			onDelete(comment) {
				this.$swal({
					icon: 'info',
					title: `Sei sicuro di voler cancellare il commento?`,
					showCancelButton: true,
					cancelButtonText: 'Annulla',
					confirmButtonText: `Conferma`,	
				}).then(result => {
					if (result.isConfirmed) {				
						this.form
							.submit('delete', route('posts.comments.destroy', {
								post: this.post, 
								comment: comment.id
							}))
							.then(response => {
								this.$swal({
									icon: 'success',
									text: 'Commento cancellato correttamente'			
								}).then(result => {
									this.refreshComments()
								})								
							})
					}
				})
			},
			async fetchComments() {
				if (this.post) {
					await this.fetch
						.get(route('api.posts.comments', this.post))
						.then(response => {
							this.$set(this, 'comments', response.data)
							this.$set(this, 'pagination', response.meta)
						})
				} else {
					await this.fetch
						.get(route('translator.comments.index'))
						.then(response => {
							this.$set(this, 'comments', response.data)
							this.$set(this, 'pagination', response.meta)
						})	
				}

				this.$bus.$emit('update-pagination', this.pagination);						
			},
			async updatePage(page) {
				this.$set(this.fetch, 'page', page)

				await this.fetchComments()
			}
		}
	}
</script>