<script>
	export default {
		data() {
			return  {
				users: [],
				selectAll: false,
				has_selection: false
			}
		},
		watch: {
			selectAll(val) {
				if (val) {
					$(".media-table .media-checkbox").prop('checked', true)
				} else {
					$(".media-table .media-checkbox").prop('checked', false)
				}

				this.has_selection = $(".media-table .media-checkbox:checked").length
			}
		}
	}
</script>