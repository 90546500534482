<template>
<div>
	<div ref="dropzone" class="shadow-sm event-upload__container animated fadeIn">
		<div class="display-flex flex-column align-items-center event-upload__label-container" :class="{'display-flex': !hasPreview, 'display-none': hasPreview}">
			<p class="fw-600 line-height-20 mb-0 center-align">{{dragDropText}}</p>
			<span v-if="maxSizeLabel > 0" class="d-block center-align text-muted font-14">{{maxSizeLabel}}</span>
		</div>
		<input type="file" ref="fileInput" class="form-control-file" @change="onChange" :accept="accept" :multiple="multiple">
		<template v-if="!multiple">
			<div class="dz-preview">
				<div v-if="isObjectPreview">
					<div class="dz-image display-flex justify-content-center align-items-center">
						<img v-if="isImage(previewFile)" :src="preview" class="img-fluid" />
						<div class="w-100 p-4" v-else>
							<i class="material-icons" v-text="getItemIcon(previewFile)"></i> 
						</div>
					</div>
					<div class="center-align">
						<span class="badge badge-secondary">{{previewFile.size|prettyBytes}}</span>
					</div>
				</div>
				<div v-else-if="isStringPreview">
					<div class="dz-image display-flex justify-content-center align-items-center">
						<span @click="onDeleteSingle" class="dz-preview-delete">
							<i class="material-icons">delete</i>
						</span>						
						<img :src="previewFile" class="img-fluid" />
					</div>
				</div>
			</div>
		</template>
	</div>
	<div v-if="multiple">
		<template v-if="view == 'preview'">
			<div class="shadow-sm dz-preview dz-file-preview dz-processing dz-success dz-complete" v-for="(item, idx) in file">
				<div class="dz-image display-flex justify-content-center align-items-center">
					<span @click="onDelete(idx)" class="dz-preview-delete">
						<i class="material-icons">delete</i>
					</span>

					<img v-if="isImage(item)" :src="item.data" />
					<div class="w-100 p-4" v-else>
						<i class="material-icons" v-text="getItemIcon(item)"></i> 
					</div>
				</div>
			</div>    
		</template>
		<template v-else-if="view == 'list'">
			<div class="mt-2 list-group">
				<template v-for="(item, idx) in file.filter(f => !f.hasOwnProperty('id'))">
					<div class="list-group-item display-flex justify-content-between">
						<strong class="truncate">{{item.file.name}}</strong>
						<span @click="onDelete(idx)" class="dz-preview-delete">
							<i class="material-icons">delete</i>
						</span>					
					</div>
				</template>
			</div>		  
		</template>
	</div>	
</div>
</template>

<script>
import {Form} from '../../helpers/Form/Form'

export default {
	model: {
		prop: 'file',
		event: 'update'
	},
	props: {
		file: [String,Array],
		maxSizeText: {
			type: String,
			default: 'JPEG, PNG, WEBP, AUDIO, VIDEO non superiore a'
		},
		dragDropText: {
			type: String,
			default: `Trascina e rilascia o fai click per il file`
		},
		multiple: {
			type: Boolean,
			default: false
		},
		accept: {
			type: String,
			default: 'image/png,image/jpeg,image/webp'
		},
		maxSize: {
			type: Number,
			default: 0 // megabytes
		},
		view: {
			type: String,
			default: 'preview'
		},
		successDeleteMediaText: String
	},
	data() {
		return {
			previewFile: null,
			preview: this.file,
			visiblePreview: false,
			dragAndDropCapable: false,
			form: new Form({})
		}
	},
	computed: {
		hasPreview() {
			if (_.isArray(this.preview)) {
				return this.file.length > 0 && !this.multiple
			}
			return !_.isEmpty(this.preview) || !_.isNull(this.previewFile)
		},
		maxSizeLabel() {
			return `${this.maxSizeText} ${this.maxSize}MB.`
		},
		isStringPreview() {
			return _.isString(this.previewFile)
		},
		isObjectPreview() {
			return _.isObject(this.previewFile)
		}
	},
	watch: {
		file(val) {
			this.$set(this, 'previewFile', val)
		}
	},
	mounted() {
		if (this.$refs.dropzone) {
			this.init()
		}
	},
	methods: {
		onDeleteSingle() {
			this.$set(this, 'preview', null)
			this.$emit('update', null);
		},

		async onDelete(idx) {
			if (this.file[idx].hasOwnProperty('id')) {
				await this.form.submit('delete', route('media.destroy', this.file[idx].id))
				this.$swal({
					icon: 'success',
					text: this.successDeleteMediaText							
				}).then(result => {

				})
			}
			this.$emit('update', this.file.filter((i, index) => index != idx));
		},
		init() {
			this.dragAndDropCapable = this.determineDragAndDropCapable();
			if (this.dragAndDropCapable) {
				['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(evt => {
					this.$refs.dropzone.addEventListener(evt, e => {
					e.preventDefault();
					e.stopPropagation();
					}, false);
				})

				this.$refs.dropzone.addEventListener('drop', e => {
					e.preventDefault()
					e.stopPropagation()

					let files = e.dataTransfer.files;
					if (! this.multiple) {
						let file = e.dataTransfer.files[0]
						let size = file.size && (file.size / Math.pow(1000, 2));

						if (this.maxSize > 0 && size > this.maxSize) {
							this.$emit('size-exceeded', {size});
							return;
						}

						let reader = new FileReader();
						reader.onload = (e) => {
							let dataURI = e.target.result;
							let splitURI = dataURI.split(';')
							let splitData = splitURI[0].split(':')

							this.$emit('update', [{
								data: dataURI, 
								file,
								mime: splitData[1]
							}]);
						}
						reader.readAsDataURL(file);
						this.previewFile = file
					} else {
						Array.from(files).forEach(file => {
							let size = file.size && (file.size / Math.pow(1000, 2));
							if (this.maxSize == 0 || size <= this.maxSize) {
								let reader = new FileReader;
								reader.onload = (e) => {
									let dataURI = e.target.result;
									let splitURI = dataURI.split(';')
									let splitData = splitURI[0].split(':')

									this.$emit('update', [...this.file, {
										data: dataURI, 
										file,
										mime: splitData[1]
									}]);
								};

								reader.readAsDataURL(file);
							} else {
								this.$emit('size-exceeded', {size});
								return;								
							}
						})
					}
				})
			}
		},
		determineDragAndDropCapable() {
			let div = document.createElement('div');

			return ( ( 'draggable' in div )
			|| ( 'ondragstart' in div && 'ondrop' in div ) )
			&& 'FormData' in window && 'FileReader' in window;
		},
		onChange(e) {
			let files = e.target.files || e.dataTransfer.files;

			if (!files.length) {
				return;
			}

			if (! this.multiple) {
				let file = files[0];
				let size = file.size && (file.size / Math.pow(1000, 2));

				if (this.maxSize > 0 && size > this.maxSize) {
					this.$emit('size-exceeded', {size});
					return;
				}

				let reader = new FileReader;

				reader.onload = (e) => {
					let dataURI = e.target.result;
					this.preview = dataURI;
					this.$emit('update', dataURI);
				};

				reader.readAsDataURL(file);
				this.previewFile = file;
			} else {
				Array.from(files).forEach(file => {
					let size = file.size && (file.size / Math.pow(1000, 2));
					if (this.maxSize == 0 || size <= this.maxSize) {
						let reader = new FileReader;
						reader.onload = (e) => {
							let dataURI = e.target.result;
							let splitURI = dataURI.split(';')
							let splitData = splitURI[0].split(':')

							this.$emit('update', [...this.file, {
								data: dataURI, 
								file,
								mime: splitData[1]
							}]);
						};

						reader.readAsDataURL(file);
					} else {
						this.$emit('size-exceeded', {size});
						return;										
					}
				})
			}
		},
		isImage(item) {
			if (item.hasOwnProperty('mime')) {
				return _.startsWith(item.mime, 'image')
			}
			return _.startsWith(item.type, 'image')
		},
		getItemIcon(item) {
			if (item.hasOwnProperty('mime')) {
				return _.startsWith(item.mime, 'video') ? 'ondemand_video' : 'audiotrack'				
			}	
			return _.startsWith(item.type, 'video') ? 'ondemand_video' : 'audiotrack'
		}
	},
	filters: {
		prettyBytes(num) {
			if (typeof num !== 'number' || isNaN(num)) {
				throw new TypeError('Expected a number');
			}

			let exponent;
			let unit;
			let neg = num < 0;
			let units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

			if (neg) {
				num = -num;
			}

			if (num < 1) {
				return (neg ? '-' : '') + num + ' B';
			}

			exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
			num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
			unit = units[exponent];

			return (neg ? '-' : '') + num + ' ' + unit;
		}
	}
}
</script>