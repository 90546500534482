<script>
	import VideoPlayer from '../Commons/VideoPlayer'
	import AudioPlayer from '../Commons/AudioPlayer'
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			data: Number
		},
		components: {AudioPlayer,VideoPlayer},
		data() {
			return {
				comment: null,
				fetch: new Form({})
			}
		},
		mounted() {
			this.getComment()
		},
		methods: {
			getComment() {
				this.fetch
					.get(route('comments.get', this.data))
					.then(response => {
						this.$set(this, 'comment', response.comment)
					})
			}
		}
	}
</script>