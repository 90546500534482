<template>
	<div>
		<div ref="emoji" class="modal bottom-sheet">
	        <picker 
	        	:style="customStyle"
	        	@select="onReacted" 
	        	:show-search="false" 
	        	:show-preview="false" 
	        	:data="emojiData" 
	        	set="facebook" 
	        />  
		</div>
	</div>
</template>

<script>
	import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
	import {Form} from '../../helpers/Form/Form'
	import data from 'emoji-mart-vue-fast/data/facebook.json'

	export default {
		props: {
			positionRight: {
				type: Boolean,
				default: false
			}
		},
		components: {Picker},
		data() {
			return {
				source: null,
				form: new Form({
					emoji: []
				}),
				emojiData: new EmojiIndex(data),
				reactable: null,
			}
		},
		computed: {
			customStyle() {
				if (this.positionRight) {
					return {
						position: 'absolute', 
						bottom: '20px', 
						right: '0px', 
						zIndex: '1050' 
					}
				}

				return {
					position: 'absolute', 
					bottom: '20px', 
					left: '0px', 
					zIndex: '1050' 
				}				
			},
		},		
		mounted() {
			$(this.$refs.emoji).modal()

			this.$bus.$on('modal:emoji:show', this.openModal)
			this.$bus.$on('modal:emoji:close', this.hideModal)
		},
		methods: {
			openModal(payload) {
				this.$set(this, 'reactable', payload)
				$(this.$refs.emoji).modal('open')

				this.initSource()				
			},
			initSource() {
				if (this.reactable) {
					if (_.endsWith(this.reactable.class_path, 'Comment')) {
						this.$set(this, 'source', route('comments.reactable', this.reactable.id))
					} else if (_.endsWith(this.reactable.class_path, 'Post')) {
						this.$set(this, 'source', route('posts.reactable', this.reactable.id))
					}
				}
			},
			hideModal() {
				$(this.$refs.emoji).modal('close')

				this.$set(this, 'reactable', null)
				this.$set(this, 'source', null)
			},
			onReacted(emoji) {
				this.$set(this.form, 'emoji', emoji)

				this.form
					.submit('put', this.source)
					.then(payload => {
						this.$bus.$emit(`${this.reactable.class_path}-${this.reactable.id}`, payload)

						this.hideModal()
					})
			},						
		}
	}
</script>