<script>
	import InfiniteLoading from 'vue-infinite-loading';
	import RoomCategoryCloneContainer from './RoomCategoryCloneContainer'
	import {Form} from '../../helpers/Form/Form'

	export default {
		components: {RoomCategoryCloneContainer},
		data() {
			return {
				infiniteId: +new Date(),
				pagination: {},
				categories: [],
				form: new Form({}),
				fetch: new Form({
					id: null,
					page: 1
				})
			}
		},
		mounted() {
			this.$bus.$on('refresh-categories', this.refreshCategories)
			this.$bus.$on('rooms-category:delete:show', this.handleCategoryDestroy)
		},		
		updated() {
			$(`.dropdown-trigger`).dropdown({
				alignment: 'right',
				coverTrigger: true,
				constrainWidth: false
			})	
		},		
		methods: {
			handleCategoryDestroy(payload) {
				this.$swal({
					icon: 'info',
					title: `Sei sicuro di voler eliminare la categoria "${payload.name}"?`,
					showCancelButton: true,
					cancelButtonText: 'Annulla',
					confirmButtonText: `Conferma`,	
				}).then(result => {
					if (result.isConfirmed) {
						this.form
							.submit('delete', route('api.categories.destroy', payload.id))
							.then(response => {
								this.$swal({
									icon: 'success',
									text: 'Categoria eliminata correttamente'			
								}).then(result => {
									this.refreshCategories()
								})
							}) 
					}
				})			
			},
			refreshCategories() {
				this.$set(this, 'pagination', {})
				this.$set(this.fetch, 'page', 1)
				this.$set(this, 'categories', [])

				this.infiniteId += 1;
			},			
            infiniteHandler($state) {
                if (!_.isEmpty(this.pagination)) {
                    if (this.pagination.current_page === this.pagination.last_page) {
                        $state.complete()
                    } else {
                        this.getCategories($state)
                    }
                } else {
                    this.getCategories($state)
                }
            },	
            getCategories($state) {
				this.fetch
					.get(route('api.categories.list'))
					.then(({meta,data}) => {
                        this.$set(this.fetch, 'page', meta.current_page + 1)
                        this.$set(this, 'pagination', meta)
                        this.$set(this, 'categories', this.categories.concat(data))		

                        if (data.length > 0) {
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
					})
            }
		}
	}
</script>