 <template>
    <div class="mb-2 audio-player align-items-center">
        <div @click="togglePlay" id="play-btn">
            <i class="material-icons">play_arrow</i>
        </div>
        <div ref="container" class="audio-wrapper">
            <audio ref="player">
                <source :src="returnUrl(media.url)" :type="media.type">
            </audio>
        </div>
        <div class="player-controls scrubber">
            <span @click="seek" class="player-seek-container" id="seek-obj-container">
                <progress id="seek-obj" value="0" max="1"></progress>
            </span>
            <div class="mt-1 player-times d-flex justify-content-between align-items-center">
                <small id="start-time" v-text="startTime"></small>
                <small id="end-time" v-text="endTime"></small>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['media'],
        data() {
            return {
                showVolume: false,
                volume: 50,
                autoPlay: false,
                durationSeconds: 0,
                loaded: false,
                container: null,
                player: null,
                playing: false,
                playBtn: null,
                startTime: '00:00',
                endTime: null
            }
        },
        computed: {
            playerIcon() {
                return this.playing ? 'pause' : 'play'
            }
        },
        watch: {
            playing(value) {
                if (value) {
                    return this.player.play();
                }
                this.player.pause();
            },
            volume(value) {
                this.showVolume = false;
                this.player.volume = this.volume / 100;
            }
        },
        mounted() {
            this.player = this.$refs.player;
            this.player.addEventListener('loadeddata', this.load);
            this.player.addEventListener('timeupdate', this.initProgressBar);
        },
        methods: {
            togglePlay() {
                this.playing = !this.playing
            },
            returnUrl(url) {
                let protocol = window.location.href
                let arrProtocol = protocol.split('/')
                let prefix = arrProtocol[0]
                if (prefix == 'https:') {
                    return url.replace('http://', 'https://')
                }
                
                return url
            },
            load() {
                if (this.player.readyState >= 2) {
                    this.loaded = true;
                    this.durationSeconds = parseInt(this.player.duration);
                    this.endTime = this.calculateTotalValue(this.durationSeconds)
                    return this.playing = this.autoPlay;
                }
                throw new Error('Failed to load sound file.');
            },
            initProgressBar() {
                let length = this.player.duration
                let current_time = this.player.currentTime;
                // calculate total length of value
                let totalLength = this.calculateTotalValue(length)
                // calculate current value time
                let currentTime = this.calculateCurrentValue(current_time);
                this.$set(this, 'startTime', currentTime)
                let progressbar = document.getElementById('seek-obj');
                progressbar.value = (this.player.currentTime / this.player.duration);
                progressbar.addEventListener("click", this.seek);
                if (this.player.currentTime == this.player.duration) {
                    document.getElementById('play-btn').className = "";
                }
            },
            seek(e) {
                const el = e.target.getBoundingClientRect();
                const seekPos = (e.clientX - el.left) / el.width;
                this.player.currentTime = parseInt(this.player.duration * seekPos);
            },
            calculateTotalValue(length) {
                let minutes = Math.floor(length / 60)
                let seconds_int = length - minutes * 60
                let seconds_str = seconds_int.toString()
                let seconds = seconds_str.substr(0, 2)
                let time = (minutes < 10 ? `0${minutes}` : minutes) + ':' + (seconds < 10 ? `0${seconds}` : seconds)
                return time;
            },
            calculateCurrentValue(currentTime) {
                let current_hour = parseInt(currentTime / 3600) % 24
                let current_minute = parseInt(currentTime / 60) % 60
                let current_seconds_long = currentTime % 60
                let current_seconds = current_seconds_long.toFixed()
                let current_time = (current_minute < 10 ? "0" + current_minute : current_minute) + ":" + (current_seconds < 10 ? "0" + current_seconds : current_seconds);
                return current_time;
            }
        }
    }
</script>