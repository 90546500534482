<script>
	import Datepicker from '../Commons/Datepicker'
	import {Form} from '../../helpers/Form/Form'

	export default {
		components: {Datepicker},
		data() {
			return {
				codes: [],
				positions: [],
				categories: [],
				room: {},
				editor: null,
				contraints: [],
				targets: [],
				fetch: new Form({}),
				form: new Form({
					type: null,
					name: null,
					code: null,
					category_id: null,
					description: null,
					position: '',
					doneif: null,
					depend_to: null,
					enable_notifications: false,
					show_publish: false,
					show_unpublish: false,
					show_target: false,
					targets_list: [],
					publish_at: null,
					unpublish_at: null,					
				})				
			}
		},
		computed: {
			lastPosition() {
				if (this.positions.length == 0) {
					return 1
				}

				let keys = Object.keys(this.positions)
				if (keys.length > 0) {
					return this.positions[_.last(keys)] + 1
				}
			},			
		},
		watch: {
			'form.show_unpublish': {
				handler(val) {
					if (!val) {
						this.$set(this.form, 'unpublish_at', null)
					}
				}
			},
			'form.show_publish': {
				handler(val) {
					if (!val) {
						this.$set(this.form, 'publish_at', null)
					}
				}				
			},
			'form.category_id': {
				handler(val) {
					let category = this.categories.find(i => i.id == val)
					if (category) {
						this.$set(this.form, 'publish_at', category.publish_at)
						this.$set(this.form, 'unpublish_at', category.unpublish_at)
						this.$set(this.form, 'targets_list', category.targets_list)
						this.$set(this.form, 'show_publish', !_.isNull(category.publish_at))
						this.$set(this.form, 'show_unpublish', !_.isNull(category.unpublish_at))
						this.$set(this.form, 'show_target', category.targets_list.length > 0)
					} else {
						this.$set(this.form, 'publish_at', this.form.publish_at)
						this.$set(this.form, 'unpublish_at', this.form.unpublish_at)
						this.$set(this.form, 'targets_list', this.room.targets.map(i => i.id))						
						this.$set(this.form, 'show_publish', !_.isNull(this.form.publish_at))
						this.$set(this.form, 'show_unpublish', !_.isNull(this.form.unpublish_at))
						this.$set(this.form, 'show_target', this.room.targets.length > 0)						
					}
				}
			}			
		},
		mounted() {
			this.$bus.$on('rooms:clone:show', this.load)
		},
		updated() {
			this.initPlugin()
		},		
		methods: {
			async getLastPositions() {
				let response = await this.fetch.get(route('api.rooms.positions'))

				this.$set(this, 'positions', response.positions)
				this.$set(this, 'codes', response.codes)
				this.$set(this, 'categories', response.categories)
				this.$set(this.form, 'position', this.lastPosition)
			},		
			initModal() {
				$(this.$refs.modal).modal()
			},					
			async load(payload) {
				this.initEditor()
				this.initModal()

				this.$set(this, 'room', payload)

				this.$set(this.form, 'type', payload.type)
				this.$set(this.form, 'doneif', payload.doneif)
				this.$set(this.form, 'category_id', payload.category ? payload.category.id : null)
				this.$set(this.form, 'depend_to', payload.depend_to)
				this.$set(this.form, 'enable_notifications', payload.enable_notifications)
				this.$set(this.form, 'targets_list', payload.targets.map(i => i.id))
				this.$set(this.form, 'show_target', payload.targets.length > 0)

				this.$set(this.form, 'show_publish', !_.isNull(payload.publish_at))
				this.$set(this.form, 'show_unpublish', !_.isNull(payload.unpublish_at))			
				this.$set(this.form, 'publish_at', payload.publish_at)
				this.$set(this.form, 'unpublish_at', payload.unpublish_at)				

				await this.getContraints()
				await this.fetchTargets()
				await this.getLastPositions()				

				$(this.$refs.modal).modal('open')
			},
			async fetchTargets() {
				await this.fetch
					.get(route('api.targets.index'))
					.then(response => {
						this.$set(this, 'targets', response.targets)
					})
			},			
			async getContraints() {
				await this.fetch
					.get(route('api.rooms.contraints', this.room))
					.then(response => {
						this.$set(this, 'contraints', response.contraints)
					})
			},
			initPlugin() {
				let vm = this

				$("select").formSelect();
			},
			initEditor() {
				if (!this.editor) {
					this.editor = new Quill(this.$refs.editor, {
						bounds: this.$refs.editor,
						modules: {
						  	'formula': true,
						  	'syntax': true,
						  	'toolbar': {
						  		container: [
								    [{'font': []}, {'size': []}],
							    	['bold', 'italic', 'underline', 'strike'],
							    	[{'color': []}, {'background': []}],
							    	[{'script': 'super'}, {'script': 'sub'
							    	}],
							    	[{'header': '1'}, {'header': '2'}, 'blockquote', 'code-block'],
							    	[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
							    	['direction', {'align': []}],
							    	['link', 'image', 'video', 'formula'],
							    	['clean'],
							    	['emoji']
						  		],		  		
						  	},
							'emoji-toolbar': true,
							'emoji-shortname': true,			  	
						},
						theme: 'snow'
					});
					// add browser default class to quill select 
					let quillSelect = $("select[class^='ql-'], input[data-link]");
					quillSelect.addClass("browser-default");

			        this.editor.on('text-change', (e) => {
				    	this.$set(this.form, 'description', this.editor.root.innerHTML);
				    });		
				}

			    this.editor.root.innerHTML = this.form.description
			},
			onSubmit() {
				this.form
					.submit('post', route('api.rooms.clone', this.room.id))
					.then(response => {
						this.$swal({
							icon: 'success',
							text: 'Stanza clonata correttamente'			
						}).then(result => {
							this.$bus.$emit('refresh-rooms')

							this.editor.root.innerHTML = ''

							$(this.$refs.modal).modal('close')
						})							
					})
			}	
		}	
	}
</script>