<script>
	import {Form} from '../../helpers/Form/Form'

	
	export default {
		props: {
			data: Object,
		},
		data() {
			return {
				form: new Form({})
			}
		},
		computed: {
			havePosts() {
				return ['simple','diary','socialemulator'].includes(this.data.type)
			},
			haveCalendar() {
				return this.data.type == 'diary'
			},
			isPool() {
				return ['pool'].includes(this.data.type)
			},
			isGroup() {
				return this.data.type == 'rotation'
			},
			editUrl() {
				return route('rooms.edit', this.data.id)
			},
			listPostUrl() {
				return route('rooms.posts.index', this.data.id)
			},
			calendarUrl() {
				return route('rooms.diary.show', this.data.id)
			},	
			statsUrl() {
				return route('rooms.charts.show', this.data.id)
			},		
			childrenUrl() {
				return ''
			},					
			statusUrl() {
				return route('rooms.status.show', this.data.id)
			}	
		},
		methods: {
			onOpenCloneModal() {
				this.$bus.$emit('rooms:clone:show', this.data)
			},			
			onOpenNotificationModal() {
				this.$bus.$emit('rooms:notification:show', this.data)
			},
			onDelete() {
				this.form
					.submit('delete', route('rooms.destroy', this.data.id))
					.then(response => {
						this.$bus.$emit('refresh-rooms')
					})
			}			
		}
	}
</script>