export const Table = {
	methods: {
		onRemoveColumn(idx) {
			let column = this.form.table.columns[idx]

			if (!_.isNumber(question.id)) {
				this.form.table.columns.splice(idx, 1)
			} else {
				this.form
					.submit('delete', route('tables.delete_column', column.id))
					.then(response => {
						this.form.table.columns.splice(idx, 1)
					})
			}			
		},
		onAddColumn() {
			let position = this.form.table.columns.length
			this.form.table.columns.push({
				title: null,
				position: position + 1
			})
		},		
	}
}