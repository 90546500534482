<script>
	import InfiniteLoading from 'vue-infinite-loading';
	import ChatMessagesContainer from './ChatMessagesContainer'
	import ChatUserItemContainer from './ChatUserItemContainer'
	import {Form} from '../../helpers/Form/Form'

	export default {
		components: {InfiniteLoading,ChatUserItemContainer,ChatMessagesContainer},
		data() {
			return {
				infiniteId: +new Date(),				
				showDropzone: false,
				threads: [],
				pagination: {},
				moderator: {},
				currentThread: {},
				fetch: new Form({
					id: null,
					page: 1
				}),
			}
		},
		mounted() {
			this.$bus.$on('refresh-threads', this.refreshThreads)
			this.$bus.$on('thread-update', this.updateThread)
		},
		methods: {
			updateThread(payload) {

			},
			refreshThreads() {
				this.$set(this.fetch, 'page', 1)
				this.$set(this, 'pagination', {})
				this.$set(this, 'threads', [])

				this.infiniteId += 1
			},			
            infiniteHandler($state) {
                if (!_.isEmpty(this.pagination)) {
                    if (this.pagination.current_page === this.pagination.last_page) {
                        $state.complete()
                    } else {
                        this.fetchThreads($state)
                    }
                } else {
                    this.fetchThreads($state)
                }
            },					
			async fetchThreads($state) {
				let {threads,moderator} = await this.fetch.get(route('threads.list_all'))

				this.$set(this.fetch, 'page', threads.pagination.current_page + 1)
				this.$set(this, 'pagination', threads.pagination)
				this.$set(this, 'threads', this.threads.concat(threads.data))
				this.$set(this, 'moderator', moderator)

                if (threads.data.length > 0) {
                    $state.loaded()
                } else {
                    $state.complete()
                }

				if (this.threads.length > 0) {
					this.initSelection({thread: _.head(this.threads)})
				}
			},
			initSelection({thread}) {
				this.$set(this, 'currentThread', thread)
				this.$bus.$emit('init:thread', thread)
			},
			isSelected(thread) {
				if (this.currentThread.hasOwnProperty('id')) {
					return thread.id == this.currentThread.id
				}
				return false
			},
			onCreateChat() {
				this.$bus.$emit('mods:thread:init-partecipants')
			}
		} 
	}
</script>