import { Form } from '../helpers/Form/Form'

export const Loggable = {
  data() {
    return {
      countDownVisible: 0,
      countDownHidden: 0,
      countdown: null,
      countdownHidden: null,
      formTracking: new Form({
        id: null,
        tracking: this.data.tracking
      }),
    }
  },

  mounted() {
    this.initObserver()
  },

  methods: {
    initObserver() {
      if (!this.$refs.loggable) {
        return
      }
      
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(async (entry) => {
          if (entry.isIntersecting) {
            this.initTimer()

            clearInterval(this.countdownHidden)

            if (this.countDownHidden > 0) {
              this.formTracking.tracking.hidden.push({
                hidden_for: this.countDownHidden,
                hidden_until_at: moment().format('YYYY-MM-DD hh:mm:ss')
              })

              this.countDownHidden = 0
              this.formTracking.submit('put', route('api.posts.tracking', this.post.id))
            }
          } else {
            clearInterval(this.countdown)

            this.initTimer('hidden')

            if (this.countDownVisible > 0) {
              this.formTracking.tracking.visible.push({
                visible_for: this.countDownVisible,
                visible_until_at: moment().format('YYYY-MM-DD hh:mm:ss')
              })

              this.countDownVisible = 0
              await this.formTracking.submit('put', route('api.posts.tracking', this.post.id))
            }
          }
        })
      }, {
        root: null, // relative to document viewport 
        rootMargin: '0px', // margin around root. Values are similar to css property. Unitless values not allowed
        threshold: 1.0 // visible amount of item shown in relation to root
      })
      observer.observe(this.$refs.loggable[0]);
    },

    initTimer(type = 'visible') {
      if (type == 'visible') {
        this.countDownVisible = 0
        // Update the count down every 1 second
        this.countdown = setInterval(() => {
          this.countDownVisible++;
        }, 1000);
      } else {
        this.countDownHidden = 0
        // Update the count down every 1 second
        this.countdownHidden = setInterval(() => {
          this.countDownHidden++;
        }, 1000);
      }
    },

    onEnded(ev) {
      if (!this.formTracking.tracking.ended_at) {
        this.formTracking.tracking.ended_at = moment().format('YYYY-MM-DD hh:mm:ss')

        if (this.formTracking.tracking.played.length > 0) {
          this.formTracking.tracking.played[this.formTracking.tracking.played.length - 1].to = this.convertTime(ev.target.player.currentTime())
          this.formTracking.tracking.played[this.formTracking.tracking.played.length - 1].raw_to = ev.target.player.currentTime()

          const rawTo = this.formTracking.tracking.played[this.formTracking.tracking.played.length - 1].raw_to
          const rawFrom = this.formTracking.tracking.played[this.formTracking.tracking.played.length - 1].raw_from
          const duration = Math.abs(rawTo - rawFrom)

          this.formTracking.tracking.played[this.formTracking.tracking.played.length - 1].duration = this.convertTime(duration)
        }

        this.formTracking.submit('put', route('api.posts.tracking', this.post.id))
      }
    },

    onSeeked(ev) {
      let lastSkip = {}
      if (this.formTracking.tracking.skipped.length > 0) {
        lastSkip = this.formTracking.tracking.skipped[this.formTracking.tracking.skipped.length - 1]
      }

      this.formTracking.tracking.skipped.push({
        skipped_at: moment().format('YYYY-MM-DD hh:mm:ss'),
        current_time: this.convertTime(ev.target.player.currentTime()),
        raw_time: ev.target.player.currentTime(),
        order: !lastSkip.hasOwnProperty('raw_time') ?
          'desc' :
          (ev.target.player.currentTime() > lastSkip.hasOwnProperty('raw_time') ? 'desc' : 'asc')
      })

      this.formTracking.submit('put', route('api.posts.tracking', this.post.id))
    },

    onPlay(ev) {
      const timestamp = moment().format('YYYY-MM-DD hh:mm:ss')
      const idx = this.formTracking.tracking.played.findIndex(i => i.played_at == timestamp)
      if (idx < 0) {
        this.formTracking.tracking.played.push({
          played_at: timestamp,
          from: this.convertTime(ev.target.player.currentTime()),
          to: '00:00:00:00',
          raw_from: ev.target.player.currentTime(),
          raw_to: 0,
          duration: '00:00:00:00'
        })

        this.formTracking.submit('put', route('api.posts.tracking', this.post.id))
      }
    },

    onPause(ev) {
      this.formTracking.tracking.paused.push({
        paused_at: moment().format('YYYY-MM-DD hh:mm:ss'),
        current_time: this.convertTime(ev.target.player.currentTime())
      })

      if (this.formTracking.tracking.played.length > 0) {
        this.formTracking.tracking.played[this.formTracking.tracking.played.length - 1].to = this.convertTime(ev.target.player.currentTime())
        this.formTracking.tracking.played[this.formTracking.tracking.played.length - 1].raw_to = ev.target.player.currentTime()

        const rawTo = this.formTracking.tracking.played[this.formTracking.tracking.played.length - 1].raw_to
        const rawFrom = this.formTracking.tracking.played[this.formTracking.tracking.played.length - 1].raw_from
        const duration = Math.abs(rawTo - rawFrom)

        this.formTracking.tracking.played[this.formTracking.tracking.played.length - 1].duration = this.convertTime(duration)
      }

      this.formTracking.submit('put', route('api.posts.tracking', this.post.id))
    },

    convertTime(input, fps) {
      var pad = function (input) { return (input < 10) ? "0" + input : input; };
      fps = (typeof fps !== 'undefined' ? fps : 24);
      return [
        pad(Math.floor(input / 3600)),
        pad(Math.floor(input % 3600 / 60)),
        pad(Math.floor(input % 60)),
        pad(Math.floor(input * fps % fps))
      ].join(':');
    },
  }
}