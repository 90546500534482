<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			data: Object,
			alertLockMessage: String,
			btnCancelLabel: String,
			btnConfirmLabel: String,
			alertConfirmLockedMessage: String,
			alertDeleteMessage: String,
			alertConfirmDeleteMessage: String,
			alertUnlockMessage: String,
			alertConfirmUnlockedMessage: String
		},
		data() {
			return {
				form: new Form({})
			}
		},
		computed: {
			editUrl() {
				return route('projects.edit', this.data.id)
			},
			lockUrl() {
				return route('projects.lock', this.data.id)
			}
		},
		methods: {
			onLockProjectModal() {
				this.$swal({
					icon: 'info',
					title: this.alertLockMessage,
					showCancelButton: true,
					cancelButtonText: this.btnCancelLabel,
					confirmButtonText: this.btnConfirmLabel,	
				}).then(result => {
					if (result.isConfirmed) {				
						this.form
							.submit('post', route('projects.lock', this.data.id))
							.then(response => {
								this.$swal({
									icon: 'success',
									text: this.alertConfirmLockedMessage		
								}).then(result => {
									this.$bus.$emit('refresh-projects')
								})								
							})
					}
				})		
			},
			onUnlockProjectModal() {
				this.$swal({
					icon: 'info',
					title: this.alertUnlockMessage,
					showCancelButton: true,
					cancelButtonText: this.btnCancelLabel,
					confirmButtonText: this.btnConfirmLabel,	
				}).then(result => {
					if (result.isConfirmed) {				
						this.form
							.submit('post', route('projects.unlock', this.data.id))
							.then(response => {
								this.$swal({
									icon: 'success',
									text: this.alertConfirmUnlockedMessage		
								}).then(result => {
									this.$bus.$emit('refresh-projects')
								})								
							})
					}
				})		
			},			
			onOpenCloneModal() {
				this.$bus.$emit('projects:clone:show', this.data)
			},
			onDeleteProject() {
				this.$swal({
					icon: 'info',
					title: this.alertDeleteMessage,
					showCancelButton: true,
					cancelButtonText: this.btnCancelLabel,
					confirmButtonText: this.btnConfirmLabel,
				}).then(result => {
					if (result.isConfirmed) {
						this.form
							.submit('delete', route('api.projects.destroy', this.data.id))
							.then(response => {
								this.$swal({
									icon: 'success',
									text: this.alertConfirmDeleteMessage
								}).then(result => {
									this.$bus.$emit('refresh-projects')
								})
							}) 
					}
				})
			},			
			onTruncateProject() {
				this.$swal({
					icon: 'info',
					title: `Sei sicuro di voler svuotare il progetto "${this.data.name}"?`,
					showCancelButton: true,
					cancelButtonText: 'Annulla',
					confirmButtonText: `Conferma`,	
				}).then(result => {
					if (result.isConfirmed) {
						this.form
							.submit('post', route('api.projects.truncate', this.data.id))
							.then(response => {
								this.$swal({
									icon: 'success',
									text: 'Progetto svuotato correttamente'			
								}).then(result => {
									this.$bus.$emit('refresh-projects')
								})
							}) 
					}
				})				
			}
		}
	}
</script>