<template>
	<div>
	    <div ref="wrapper" id="full-wrapper">
	        <div id="full-container">
	            <div ref="editor"></div>
	        </div>
	    </div>  
	</div>	
</template>

<script>
	export default {
		model: {
			prop: 'body',
			event: 'update'
		},
		props: ['body'],
		data() {
			return {
				editor: null,
				content: null,
			}
		},
		watch: {
			content(newVal, oldVal) {
				if (this.editor) {
				  	if (newVal && newVal !== this.content) {
					    this.content = newVal
					    this.quill.pasteHTML(newVal)
				  	} else if (!newVal) {
				    	this.editor.setText('')
				  	}
				}
			},			
			// Watch content change
			body(newVal, oldVal) {
				if (this.editor) {
					if (newVal && newVal !== this.content) {
						this.content = newVal
						this.editor.pasteHTML(newVal)
					} else if (!newVal) {
						this.editor.setText('')
					}
				}
			},
		},
		mounted() {
			this.initEditor()
		},
		methods: {
			initEditor() {
				this.editor = new Quill(this.$refs.editor, {
					bounds: this.$refs.editor,
					modules: {
					  	'formula': true,
					  	'syntax': true,
					  	'toolbar': {
					  		container: [
							    [{'font': []}, {'size': []}],
						    	['bold', 'italic', 'underline', 'strike'],
						    	[{'color': []}, {'background': []}],
						    	[{'script': 'super'}, {'script': 'sub'
						    	}],
						    	[{'header': '1'}, {'header': '2'}, 'blockquote', 'code-block'],
						    	[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
						    	['direction', {'align': []}],
						    	['link', 'image', 'video', 'formula'],
						    	['clean'],
						    	['emoji']
					  		],		  		
					  	},
						'emoji-toolbar': true,
						'emoji-shortname': true,			  	
					},
					theme: 'snow'
				});
				// add browser default class to quill select 
				let quillSelect = $(this.$refs.wrapper).find("select[class^='ql-'], input[data-link]");
				quillSelect.addClass("browser-default");		

				// Set editor content
				if (this.body || this.content) {
					this.editor.pasteHTML(this.body || this.content)
				}

				// Mark model as touched if editor lost focus
				this.editor.on('selection-change', range => {
					if (!range) {
						this.$emit('blur', this.quill)
					} else {
						this.$emit('focus', this.quill)
					}
				})	

				this.editor.on('text-change', (delta, oldDelta, source) => {
					let html = this.$refs.editor.children[0].innerHTML
					const quill = this.editor
					const text = this.editor.getText()
					if (html === '<p><br></p>') html = ''
					this.content = html
					this.$emit('update', this.content)
					this.$emit('change', { html, text, quill })
				})							
			},
		}
	}
</script>