<template>
	<div class="comment-textarea-container">
		<div v-if="showDropzone" class="mb-1">
			<dropzone 
				:drag-drop-text="dragDropText"
				accept="image/png,image/jpeg,image/webp,video/mp4,video/x-m4v,video/*,audio/*"
				@size-exceeded="onExceededSize" 
				multiple 
				v-model="form.files"></dropzone>
		</div>

		<slot name="comment">
			<div class="comment-form-container display-flex justify-content-between align-items-center">
				<span @click="toggleDropdown" class="handle-dropzone mr-1 display-flex align-items-center clickable">
					<i class="material-icons">camera_alt</i>
				</span>
				<template v-if="deleteDisabled">
					<slot name="autosave-delete" :data="{autosaveForm, onDelete}">
						<span v-if="!autosaveForm.status && autosaveForm.id" @click="onDelete" class="handle-autosave mr-1 display-flex align-items-center clickable">
							<i class="material-icons">delete</i>
						</span>				
					</slot>
				</template>

				<div ref="editor" class="comment-form-control bubble-editor"></div>
				<button class="btn-small waves-effect waves-light" @click="submit" :class="{'disabled': form.submitted}" :disabled="form.submitted">
					{{buttonText}} <i class="material-icons right">send</i>
				</button>			
			</div>
		</slot>

		<slot name="processing" :data="{form, autosaveForm, waitingTextLabel}">
			<div class="mt-1" v-if="form.submitted && !autosaveForm.submitted">
				<small class="animated fadeIn" v-text="waitingTextLabel"></small>
				<div class="progress">
					<div class="indeterminate"></div>
				</div>		
			</div>
		</slot>
	</div>
</template>

<script>
	import Quill from 'quill'
	import Clipboard from 'quill/modules/clipboard'
	import Keyboard from 'quill/modules/keyboard'
	import Dropzone from '../Commons/Dropzone'
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			formId: Number,
			placeholder: String,
			customSource: String,
			buttonText: String,
			draftSource: String,
			waitingTextLabel: String,
			dragDropText: String,
			deleteDisabled: {
				type: Boolean,
				default: false
			}
		},
		components: {Dropzone},
		data() {
			return {
				autosaveEnabled: false,
				showDropzone: false,
				source: this.customSource,
				editor: null,
				fetch: new Form({}),
				autosaveForm: new Form({
					id: null,
					body: null,
					status: false
				}),
				form: new Form({
					id: null,
					body: null,
					files: [],
					videos: [],
					status: true
				}),
				upload: new Form({
					file: null,
					site: process.env.MIX_APP_SITE_ID
				})
			}
		},
		computed: {
			isQuillEmpty() {
				if (this.editor) {
					return this.editor.getContents().ops[0].insert == '\n' && this.editor.getLength() < 2;
				}
				true
			}
		},
		mounted() {
			this.init()		
			this.$bus.$on(`prefix-user_${this.formId}`, this.setUserPrefix)
		},
		methods: {		
			onDelete() {
				this.autosaveForm
					.submit('delete', route('comments.destroy', this.autosaveForm.id))
					.then(response => {
						this.editor.root.innerHTML = ''
						this.autosaveForm.reset()
					})
			},
			async getDraft(payload = {}) {
				if (payload.hasOwnProperty('source')) {
					this.$set(this, 'source', payload.source)
				}

				await this.fetch
					.get(this.draftSource)
					.then(response => {
						let message = payload.user

						if (response.comment.hasOwnProperty('body')) {
							this.$set(this.autosaveForm, 'id', response.comment.id)
							this.$set(this.form, 'id', response.comment.id)
							this.$set(this.form, 'body', response.comment.body)

							this.editor.root.innerHTML = response.comment.body
						} else if (payload.hasOwnProperty('user')) {
							this.editor.setContents([
								{ insert: payload.user, attributes: {bold: true} },
								{ insert: ' ' }
							]);		

							this.editor.setSelection(0, this.editor.getText().length)				
						}													

						this.editor.focus();
					})

		        this.editor.on('text-change', (e) => {
		        	let content = this.editor.root.innerHTML.replace(/<p><br[\/]?><[\/]?p>/g, '');

			    	this.$set(this.form, 'body', content);
			    	this.$set(this.autosaveForm, 'body', content);

			    	if (payload.hasOwnProperty('data')) {
				    	if (this.formId == payload.data.commentable_id) {
			    			this.autosave(this)
			    		}
			    	} else if (!this.formId) {
			    		this.autosave(this)
			    	}
			    });							
			},
			autosave: _.debounce(async (vm) => {
				if (vm.source) {
					if (!vm.isQuillEmpty) {
						await vm.autosaveForm
							.submit('post', vm.source)
							.then(response => {
								vm.$set(vm.form, 'id', response.comment.id)
								vm.$set(vm.autosaveForm, 'id', response.comment.id)
							})	
					}
				}		
			}, 500),
			onExceededSize(payload) {
				this.$swal({
					icon: 'error',
					text: `Dimensione file non valida: ${payload.size.toFixed(2)}MB`
				});
			},
			toggleDropdown() {
				this.showDropzone = !this.showDropzone
				this.$set(this.form, 'files', [])
			},
			async init() {
				this.editor = new Quill(this.$refs.editor, {
					bounds: '.bubble-editor',
					placeholder: this.placeholder,
					modules: {
						'emoji-textarea': true,
						'toolbar': false,
				        'clipboard': {
				            matchVisual: false
				        }										
					},
					theme: 'bubble'
				});

				this.editor.root.addEventListener('keydown', evt => {
					if (evt.keyCode == 13) {
						evt.preventDefault()
						evt.stopPropagation();
						this.submit()
					}
				});				

				await this.getDraft()			
			},
			submit() {
				this.form.submitted = true;
				this.$set(this.form, 'status', true)	

				if (this.isQuillEmpty && this.form.files.length == 0) {
					this.form.submitted = false
					return
				}
				let content = this.editor.root.innerHTML.replace(/<p><br[\/]?><[\/]?p>/g, '');
				if (content.length > 0) {
					this.$set(this.form, 'body', content)	
				}
				
				setTimeout(async () => {
					let videos = this.getVideos()

					for (let i in videos) {
						let formData = new FormData
						formData.append('file', videos[i].file)
						formData.append('site', process.env.MIX_APP_SITE_ID)

						let {media,url} = await this.upload
							.submitWith('post', process.env.MIX_APP_VIDEO_UPLOAD_URL, formData)

						this.form.videos.push({
							id: media.id,
							mime: media.mime_type,
							url
						})
					}

					await this.form
						.submit('post', this.source)
						.then(response => {
							this.form.reset()
							this.autosaveForm.reset()

							if (!response.comment.is_valid) {
								this.$swal({
									icon: 'error',
									text: response.comment.validation_message
								});							
							} else {
								this.$bus.$emit('update-room-pagination')
							}
							
							this.$emit('created', response)
							this.showDropzone = false
							this.$set(this.form, 'files', [])
							this.$set(this.form, 'videos', [])
							this.editor.setText('')
						})
				}, 2000)
			},
			async setUserPrefix(payload) {
				if (this.formId != payload.data.commentable_id) {
					return
				} else {
					await this.getDraft(payload)							
				}
			},
			getVideos() {
				let videos = []
				this.form.files.forEach((i, idx) => {
					if (_.startsWith(i.mime, 'video')) {
						videos.push(i)

						this.form.files.splice(idx, 1)
					}
				})			

				return videos	
			}
		},	
	}
</script>