<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		data() {
			return {
				subject: null,
				fetch: new Form({}),
				partecipants: [],
				form: new Form({
					id: null,
					comment: null,
					room_id: null,
					partecipants: []
				}),
				source: route('threads.store')
			}
		},
		mounted() {
			$(this.$refs.modal).modal({
				dismissible: false
			});
			this.$bus.$on('mods:thread:init-partecipants', this.init)
		},
		methods: {
			close() {
				this.form.reset()
				$(this.$refs.modal).modal('close')
			},
			async init(payload) {
				if (payload && payload.hasOwnProperty('item')) {
					this.$set(this.form, 'comment', payload.item)
				}

				await this.getPartecipants()

				$(this.$refs.modal).modal('open');
			},
			async getPartecipants() {
				await this.fetch
					.get(route('api.mods.list'))
					.then(response => {
						this.$set(this, 'partecipants', response.data)
					})
			},
			onSubmit($ev) {
				if (this.form.comment) {
	                this.$set(this.form, 'room_id', this.form.comment.room_id)
	            }

                this.form
                    .submit('post', route('threads.store'))
                    .then(response => {
                    	if (this.form.comment) {
	                        this.$bus.$emit('refresh-threads', {id: 'mods'})
	                        this.$bus.$emit('thread:init', {item: this.form.comment, thread: response.data})
	                        this.toggleDetail($ev)
	                    } else {
	                    	this.$bus.$emit('refresh-threads')
	                    }

                        this.close()
                    })				
			},
            toggleDetail(e) {
                let $trigger = $('body').find('.sidenav-trigger');

                if (e.target && $trigger.length) {
                    let sidenavId = M.getIdFromTrigger($trigger[0]);

                    let sidenavInstance = document.getElementById(sidenavId).M_Sidenav;
                    if (sidenavInstance) {
                        sidenavInstance.open($trigger);                     
                    }
                    e.preventDefault();
                }
            },    			
		}
	}
</script>