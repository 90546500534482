<script>
	import FullCalendar from '@fullcalendar/vue'
	import timeGridPlugin from '@fullcalendar/timegrid';
	import dayGridPlugin from '@fullcalendar/daygrid'
	import interactionPlugin from '@fullcalendar/interaction'
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			room: Number
		},
		components: {FullCalendar},
		data() {
			return {
				calendarOptions: {
					plugins: [ dayGridPlugin, interactionPlugin ],
					initialView: 'dayGridMonth',
					eventClick: this.onSelectEvent,
					datesSet: this.onDatesSet,
					events: []
				},				
				form: new Form({
					id: null,
					page: 1,
					current_month: null,
					current_date: null,
					post_id: null,
				}),
				users: [],
				pagination: {},
				links: {}
			}
		},
		computed: {
			disabledPrev() {
				if (this.pagination) {
					return this.form.page == 1
				}
				return false
			},
			disabledNext() {
				if (this.pagination) {
					return this.pagination.current_page >= this.pagination.total_pages
				}
				return false
			}			
		},
		watch: {
			'form.post_id': {
				handler(val) {
					this.fetchEvents()
				}
			}
		},
		mounted() {
			$(this.$refs.modal).modal();
		},
		methods: {
			onDatesSet(payload) {
				let currentDate = payload.view.getCurrentData().currentDate
				this.$set(this.form, 'current_month', moment(currentDate).format('YYYY-MM'))

				//this.fetchEvents()
			},
			onSelectEvent(payload) {
				this.$set(this.form, 'current_date', payload.event.startStr)		

				this.form
					.get(route('rooms.users.index', this.room))
					.then(response => {
						this.$set(this, 'users', response.users.data)
						this.$set(this, 'pagination', response.users.pagination)

						$(this.$refs.modal).modal('open');
					})
			},
			fetchEvents() {
				this.form
					.get(route('rooms.post_comments_count', this.room))
					.then(response => {
						this.$set(this.calendarOptions, 'events', response.comments)
					})
			},
			onNext() {
				if (this.pagination.current_page < this.pagination.total_pages) {
					this.$set(this.form, 'page', this.pagination.current_page + 1)

					this.form
						.get(route('rooms.users.index', this.room))
						.then(response => {
							this.$set(this, 'users', response.posts.data)
							this.$set(this, 'pagination', response.posts.pagination)

							$(this.$refs.modal).modal('open');
						})					
				}
			},
			onPrev() {
				if (this.form.page > 0) {
					this.$set(this.form, 'page', this.form.page - 1)

					this.form
						.get(route('rooms.users.index', this.room))
						.then(response => {
							this.$set(this, 'users', response.posts.data)
							this.$set(this, 'pagination', response.posts.pagination)

							$(this.$refs.modal).modal('open');
						})					
				}
			}			
		}
	}
</script>